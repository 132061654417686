const React = require('react');
const numeral = require('numeral');
const HoursCell = require('./ProjectHoursCell.react');
const TableCell = require('../../../../../../../common/react/Table2/TableCell.react');

const formatTotalHours = (totalHours) => numeral(totalHours).format('0,0.[00]');

const ProjectTotalCell = ({
  value, name, className, editable, canEdit, isHoliday,
  isWaiting, feedback, onChange, updateHours, showTooltip, isInWhitelist, reasonCannotEdit,
  totalHours,
}) => {
  const cannotEditCell = `${className} timesheet-weekly__cell-hours timesheet-weekly__cell-total`;
  const getCannotEditCellContent = (children) => <p data-testid="timesheet-weekly-cell">{children}</p>;
  if (canEdit) {
    return (
      <HoursCell isHoliday={isHoliday}
        className={className}
        name={name}
        value={value}
        totalHours={formatTotalHours(totalHours)}
        editable={editable}
        isWaiting={isWaiting}
        feedback={feedback}
        onChange={onChange}
        updateHours={updateHours}
        showTooltip={showTooltip}
        isInWhitelist={isInWhitelist} />
    );
  }
  return (
    <TableCell
      name={name}
      className={cannotEditCell}>
      {
        reasonCannotEdit.type === 'lines' || totalHours === null ? getCannotEditCellContent('--') : getCannotEditCellContent(formatTotalHours(totalHours))
      }
    </TableCell>
  );
};

module.exports = ProjectTotalCell;
