const React = require('react');
const { Provider } = require('react-redux');
const Team = require('./components/Team.react');
require('./style.scss');

module.exports = ({ store }) => (
  <Provider store={store}>
    <Team />
  </Provider>
);
