const React = require('react');
const { connect } = require('react-redux');
const $ = require('jquery');
const moment = require('moment');
const actions = require('../../actions');
const Sidebar = require('../../../../../../../common/react/sidebar/Sidebar.react');
const OutlinedSelect = require('../../../../../../../common/react/inputs/Select/OutlinedSelect/OutlinedSelect.react');
const OutlinedTextField = require('../../../../../../../common/react/inputs/TextField/OutlinedTextField/OutlinedTextField.react');
const SelectItem = require('../../../../../../../common/react/inputs/Select/SelectItem.react');
const InputWrapper = require('../../../../../../../common/react/sidebar/SidebarInputWrapper.react');
const Row = require('../../../../../../../common/react/sidebar/SidebarInputRow.react');
const Switch = require('../../../../../../../common/react/inputs/Switch/Switch.react');
const WarningButton = require('../../../../../../../common/react/Button/WarningButton.react');
const ToggleArchiveModal = require('../modals/ToggleArchiveModal.react');
const ExternalIdsModal = require('../modals/ExternalIdsModal.react');
const modalService = require('../../../../../../../common/react/Modal/ModalManager/services/ModalService');
const RoundedButton = require('../../../../../../../common/react/Button/RoundedButton.react');
const Accordion = require('../../../../../../../common/react/Accordion/Accordion.react');
const Typography = require('../../../../../../../common/react/Typography/Typography.react');
const IconButton = require('../../../../../../../common/react/Button/IconButton.react');

class EditUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: JSON.parse(JSON.stringify(this.props.user)),
      isSaving: false,
      error: null,
      editMode: false,
      tagsAutocomplete: {},
      changeLog: null,
    };
  }

  componentDidMount() {
    this.getTagsAutocomplete();
    this.props.getChangeLog(this.state.user.employee.id).done((response) => {
      this.setState({ changeLog: response });
    });
  }

  handleChange({ what, info = null }, e) {
    const user = JSON.parse(JSON.stringify(this.state.user));
    switch (what) {
      case 'role':
        user.role = this.props.roles.find((item) => item.id === e.target.value);
        break;
      case 'level':
        user.level = this.props.levels.find((item) => item.id === e.target.value);
        user.job_title = null;
        break;
      case 'job_title':
        user.job_title = this.props.jobTitles.find((item) => item.id === e.target.value);
        break;
      case 'price_list':
        user.price_list = this.props.pricelists.find((item) => item.id === e.target.value);
        break;
      case 'tag': {
        const selectedTag = (
          this.state.tagsAutocomplete[info.category.id]
            .find((item) => item.id === e.target.value)
        );
        const prevTagIndex = user.tags.findIndex((tag) => tag?.category?.id === info.category.id);
        if (prevTagIndex !== -1) {
          user.tags[prevTagIndex] = selectedTag;
        } else {
          user.tags.push(selectedTag);
        }
        break;
      }
      case 'employee.visible':
        user.employee.visible = !user.employee.visible;
        break;
      case 'employee.timesheet_needed':
        user.employee.timesheet_needed = !user.employee.timesheet_needed;
        break;
      case 'employee.allocation_manager':
        user.employee.allocation_manager = (
          this.props.allUsers.find((item) => item.employee.id === e.target.value).employee
        );
        break;
      default:
        break;
    }
    this.setState(() => ({ user }));
  }

  onSave() {
    this.setState({
      isSaving: true,
      editMode: false,
    });

    const promises = [];
    if (
      this.state.user.level.id !== this.props.user.level?.id
      || this.state.user.price_list.id !== this.props.user.price_list?.id
    ) {
      promises.push(this.props.savePriceListLevel.bind(this,
        this.state.user.person.id,
        {
          levelId: this.state.user.level.id,
          pricelistId: this.state.user.price_list.id,
        }));
    }
    if (this.state.user.role.id !== this.props.user.role?.id) {
      promises.push(this.props.saveRole.bind(this,
        this.state.user.person.id,
        { roleId: this.state.user.role.id }));
    }
    if (
      this.state.user.job_title?.id !== this.props.user.job_title?.id
      && typeof this.state.user.job_title?.id === 'number'
    ) {
      promises.push(this.props.saveJobTitle.bind(this,
        this.state.user.person.id,
        { jobTitleId: this.state.user.job_title.id }));
    }
    this.state.user.tags.forEach((tag) => {
      if (!this.props.user.tags.find((item) => tag.id === item.id)) {
        promises.push(this.props.saveTag.bind(this,
          this.state.user.person.id,
          {
            tagId: tag.id,
            employeeTagId: (
              this.props.user.tags.find((item) => (
                item.category.id === tag.category.id
              ))?.employee_tag_id
            ),
          }));
      }
    });
    if (this.state.user.employee.visible !== this.props.user.employee.visible) {
      const promise = this.state.user.employee.visible
        ? this.props.showOnPlanning
        : this.props.hideFromPlanning;
      promises.push(promise.bind(this, this.state.user.employee.id));
    }
    if (
      this.state.user.employee.timesheet_needed
      !== this.props.user.employee.timesheet_needed
    ) {
      promises.push(
        this.props.setTimesheetNeeded.bind(this,
          this.state.user.person.id,
          { needed: this.state.user.employee.timesheet_needed }),
      );
    }
    if (
      this.state.user.employee.allocation_manager?.id
      !== this.props.user.employee.allocation_manager?.id
      && typeof this.state.user.employee.allocation_manager?.id === 'number'
    ) {
      promises.push(this.props.updateAllocationManager.bind(this,
        this.state.user.person.id,
        { allocationManagerId: this.state.user.employee.allocation_manager.id }));
    }

    const executeSequentially = (d) => (
      d.reduce((p, o) => p.then((r) => o(r)),
        $.Deferred().resolve('').promise())
    );

    executeSequentially(promises)
      .done(() => {
        this.props.onCloseClick();
        this.props.getItems();
      })
      .fail((response) => {
        this.setState({ isSaving: false });
        if (typeof response === 'string') {
          this.setState({ error: response });
        }
      });
  }

  onEdit() {
    this.setState({ editMode: true });
  }

  getTagsAutocomplete() {
    this.props.tagsCategories.forEach((category) => {
      this.props.getTagsAutocomplete({ category: category.id }).done((response) => {
        this.setState((state) => ({
          ...state,
          tagsAutocomplete: {
            ...state.tagsAutocomplete,
            [category.id]: response,
          },
        }));
      });
    });
  }

  getRoleItems() {
    return this.props.roles.map((item) => (
      <SelectItem key={item.id} value={item.id}>
        {item.name}
      </SelectItem>
    ));
  }

  getLevelItems() {
    return this.props.levels.map((item) => (
      <SelectItem key={item.id} value={item.id}>
        {item.text}
      </SelectItem>
    ));
  }

  getJobTitlesItems() {
    return this.props.jobTitles
      .filter((item) => item.level.id === this.state.user.level.id)
      .map((item) => (
        <SelectItem key={item.id} value={item.id}>
          {item.name}
        </SelectItem>
      ));
  }

  getPricelistsItems() {
    return this.props.pricelists.map((item) => (
      <SelectItem key={item.id} value={item.id}>
        {item.name}
      </SelectItem>
    ));
  }

  getTagsItems(category) {
    return (
      ((this.state.tagsAutocomplete[category.id] ?? []).map((item) => (
        <SelectItem key={item.id} value={item.id}>
          {item.name}
        </SelectItem>
      )))
    );
  }

  getAllocationManagerItems() {
    return this.props.allUsers.filter((user) => user.employee.type !== 2).map((item) => (
      <SelectItem key={item.employee.id} value={item.employee.id}>
        {item.person.name} {item.person.surname}
      </SelectItem>
    ));
  }

  getBody() {
    return (
      <div className="team-sidebar__body">
        <Row>
          <InputWrapper>
            <OutlinedTextField
              id="user.person.name"
              name="user.person.name"
              label="Name"
              autocomplete="off"
              required
              readOnly
              value={this.state.user.person.name} />
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <OutlinedTextField
              id="user.person.surname"
              name="user.person.surname"
              label="Surname"
              autocomplete="off"
              required
              readOnly
              value={this.state.user.person.surname} />
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <OutlinedTextField
              id="user.employee.email"
              name="user.employee.email"
              type="email"
              label="Email"
              autocomplete="off"
              required
              readOnly
              value={this.state.user.employee.email} />
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <OutlinedSelect
              label="Level"
              required
              id="user.level"
              name="user.level"
              readOnly={!this.state.editMode}
              value={this.state.user.level.id}
              onChange={this.handleChange.bind(this, { what: 'level' })}>
              {this.getLevelItems()}
            </OutlinedSelect>
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <OutlinedSelect
              label="Role"
              required
              id="user.role"
              name="user.role"
              readOnly={!this.state.editMode}
              value={this.state.user.role.id}
              onChange={this.handleChange.bind(this, { what: 'role' })}>
              {this.getRoleItems()}
            </OutlinedSelect>
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <OutlinedSelect
              label="Job Title"
              id="user.job_title"
              name="user.job_title"
              readOnly={!this.state.editMode}
              value={this.state.user.job_title?.id}
              onChange={this.handleChange.bind(this, { what: 'job_title' })}>
              {this.getJobTitlesItems()}
            </OutlinedSelect>
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <OutlinedSelect
              label="Pricelist"
              required
              id="user.price_list"
              name="user.price_list"
              errorText={this.state.user.price_list?.isExpired ? 'Pricelist Expired' : undefined}
              readOnly={!this.state.editMode}
              value={this.state.user.price_list?.id}
              onChange={this.handleChange.bind(this, { what: 'price_list' })}>
              {this.getPricelistsItems()}
            </OutlinedSelect>
          </InputWrapper>
        </Row>
        { typeof this.state.user.employee.location === 'string' && (
          <Row>
            <InputWrapper>
              <OutlinedTextField
                id="user.employee.location"
                name="user.employee.location"
                label="Location"
                autocomplete="off"
                readOnly
                value={this.state.user.employee.location} />
            </InputWrapper>
          </Row>
        )}
        { typeof this.state.user.employee.workHourCapacity === 'string' && (
          <Row>
            <InputWrapper>
              <OutlinedTextField
                id="user.employee.workHourCapacity"
                name="user.employee.workHourCapacity"
                label="Capacity"
                autocomplete="off"
                readOnly
                value={this.state.user.employee.workHourCapacity} />
            </InputWrapper>
          </Row>
        )}
        { this.props.tagsCategories.map((category) => (
          <Row key={category.id}>
            <InputWrapper>
              <OutlinedSelect
                label={`Tag ${category.name}`}
                id={`user.tag_${category.id}`}
                name={`user.tag_${category.id}`}
                readOnly={!this.state.editMode}
                value={(
                  this.state.user.tags?.find((tag) => tag?.category?.id === category.id)?.id
                )}
                onChange={this.handleChange.bind(this, { what: 'tag', info: { category } })}>
                {this.getTagsItems(category)}
              </OutlinedSelect>
            </InputWrapper>
          </Row>
        ))}
        <Row>
          <InputWrapper>
            <div
              className="team-sidebar__field"
              style={{ padding: '0' }}>
              <OutlinedTextField
                id="user.employee.external_ids"
                name="user.employee.external_ids"
                label="ID Code"
                autocomplete="off"
                readOnly
                value={this.state.user.employee.external_ids?.[0]?.external_id} />
              <IconButton
                icon="edit"
                label="Edit ID Code"
                disabled={!this.state.editMode}
                onClick={this.showExternalIdsModal.bind(this)}
                border={false} />
            </div>
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <OutlinedSelect
              label="Allocation Manager"
              id="user.employee.allocation_manager"
              name="user.employee.allocation_manager"
              readOnly={!this.state.editMode}
              value={this.state.user.employee.allocation_manager?.id}
              onChange={this.handleChange.bind(this, { what: 'employee.allocation_manager' })}>
              {this.getAllocationManagerItems()}
            </OutlinedSelect>
          </InputWrapper>
        </Row>
        <Row>
          <div
            className="team-sidebar__field"
            style={{ margin: '8px 0 0 0' }}>
            <Switch
              label="Hide from People Allocation"
              name="user.employee.visible"
              id="user.employee.visible"
              disabled={!this.state.editMode}
              checked={!this.state.user.employee.visible}
              onChange={this.handleChange.bind(this, { what: 'employee.visible' })} />
          </div>
        </Row>
        <Row>
          <div className="team-sidebar__field">
            <Switch
              label="Timesheet Optional"
              name="user.employee.timesheet_needed"
              id="user.employee.timesheet_needed"
              disabled={!this.state.editMode}
              checked={!this.state.user.employee.timesheet_needed}
              onChange={this.handleChange.bind(this, { what: 'employee.timesheet_needed' })} />
          </div>
        </Row>
        <Row>
          <div className="team-sidebar__field">
            <div>
              <Typography
                uppercase
                color={Typography.COLORS.DUSTY_GRAY}
                weight={Typography.WEIGHTS.BOLD}
                components={Typography.COMPONENTS.H3}
                size={Typography.SIZES.PX11}>
                Archive
              </Typography>
            </div>
            <div style={{ flex: '0' }}>
              { this.state.user.employee.type === 0
                ? (
                  <WarningButton
                    disabled={!this.state.editMode}
                    onClick={this.toggleArchive.bind(this)}>Archive
                  </WarningButton>
                )
                : (
                  <RoundedButton
                    disabled={!this.state.editMode}
                    onClick={this.toggleArchive.bind(this)}>Unarchive
                  </RoundedButton>
                )}
            </div>
          </div>
        </Row>
        <Row>
          <div className="team-sidebar__field">
            <Accordion
              label={(
                <Typography
                  uppercase
                  color={Typography.COLORS.DUSTY_GRAY}
                  weight={Typography.WEIGHTS.BOLD}
                  component={Typography.COMPONENTS.H3}
                  size={Typography.SIZES.PX11}>
                  Changelog
                </Typography>
              )}>
              { this.state.changeLog?.map((cl) => (
                <div
                  key={cl.id}
                  style={{ marginBottom: '10px', lineHeight: '1.4' }}>
                  <Typography
                    component={Typography.COMPONENTS.P}
                    size={Typography.SIZES.PX11}>
                    "{cl.type}" changed from {cl.from} to {cl.to}
                  </Typography>
                  <Typography
                    color={Typography.COLORS.DUSTY_GRAY}
                    component={Typography.COMPONENTS.P}
                    size={Typography.SIZES.PX11}>
                    {moment(cl.date.date).format('YYYY-MM-DD HH:mm')} - {cl.updated_by}
                  </Typography>
                </div>
              )) || <div />}
            </Accordion>
          </div>
        </Row>
      </div>
    );
  }

  toggleArchive() {
    function onSuccess() {
      this.props.onCloseClick();
    }
    modalService.openModal(
      <ToggleArchiveModal
        user={this.state.user}
        onSuccess={onSuccess.bind(this)} />,
    );
  }

  showExternalIdsModal() {
    function onSuccess() {
      this.props.getItems().done(() => {
        this.setState((state) => ({
          ...state,
          user: {
            ...state.user,
            employee: {
              ...state.user.employee,
              external_ids: this.props.allUsers
                .find((user) => (
                  user.employee.id === state.user.employee.id
                )).employee.external_ids,
            },
          },
        }));
      });
    }
    modalService.openModal(
      <ExternalIdsModal
        user={this.state.user}
        onSuccess={onSuccess.bind(this)} />,
    );
  }

  canEdit() {
    return !this.state.editMode;
  }

  canSave() {
    return !!(
      this.state.user.person.name
      && this.state.user.person.surname
      && this.state.user.employee.email
      && typeof this.state.user.role?.id === 'number'
      && typeof this.state.user.level?.id === 'number'
      && typeof this.state.user.price_list?.id === 'number'
    );
  }

  hasUnsavedChanges() {
    return !!(
      this.state.user.role?.id !== this.props.user.role?.id
      || this.state.user.level?.id !== this.props.user.level?.id
      || this.state.user.job_title?.id !== this.props.user.job_title?.id
      || this.state.user.price_list?.id !== this.props.user.price_list?.id
      || this.props.tagsCategories?.some((category) => (
        this.state.user.tags.find((tag) => tag?.category?.id === category.id)?.id
        !== this.props.user.tags.find((tag) => tag?.category?.id === category.id)?.id
      ))
      || this.state.user.employee.visible !== this.props.user.employee.visible
      || (
        this.state.user.employee.timesheet_needed
        !== this.props.user.employee.timesheet_needed
      )
      || (
        this.state.user.employee.allocation_manager?.id
        !== this.props.user.employee.allocation_manager?.id
      )
    );
  }

  render() {
    return (
      <Sidebar
        title="User details"
        error={this.state.error}
        hasUnsavedChanges={this.hasUnsavedChanges.bind(this)()}
        isSaving={this.state.isSaving}
        canSave={this.canSave.bind(this)()}
        canEdit={this.canEdit.bind(this)()}
        onClose={this.props.onCloseClick}
        onSave={this.onSave.bind(this)}
        onCancel={this.props.onCloseClick}
        onEdit={this.onEdit.bind(this)}
        body={this.getBody.bind(this)()} />
    );
  }
}

module.exports = connect((state) => ({
  allUsers: state.allUsers,
  levels: state.levels,
  roles: state.roles,
  jobTitles: state.jobTitles,
  pricelists: state.pricelists,
  tagsCategories: state.tagsCategories,
}), {
  savePriceListLevel: actions.savePriceListLevel,
  saveRole: actions.saveRole,
  saveJobTitle: actions.saveJobTitle,
  saveTag: actions.saveTag,
  showOnPlanning: actions.showOnPlanning,
  setTimesheetNeeded: actions.setTimesheetNeeded,
  hideFromPlanning: actions.hideFromPlanning,
  updateAllocationManager: actions.updateAllocationManager,
  getItems: actions.getItems,
  getTagsAutocomplete: actions.getTagsAutocomplete,
  getChangeLog: actions.getChangeLog,
})(EditUser);
