const React = require('react');

/**
 * A filler block representing an hour that exceed those defined by the employee work hour capacity. Use isBlank = true,
 * to add a blank (transparent) block, use isBlank = false to add a block with the capacity background pattern.
 * Example: a day has 8 workable hours, 2 are planned on some project, the work hour capacity for the user is 6 hours.
 * We need to add 4 CapacityHour with isBlank = true, and 2 CapacityHour with isBlank = false to fill the day.
 *
 * @param isBlank boolean Whether the block should be blank or highlighted with the capacity pattern.
 */
const CapacityHour = ({ isBlank = false }) => {
  const className = `planning-calendar__hour ${!isBlank && 'planning-calendar__hour--capacity'}`;
  return (<div className={className} />);
};

module.exports = CapacityHour;
