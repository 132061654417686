const React = require('react');
const { connect } = require('react-redux');
const actions = require('../../actions');
const Sidebar = require('../../../../../../../common/react/sidebar/Sidebar.react');
const OutlinedTextField = require('../../../../../../../common/react/inputs/TextField/OutlinedTextField/OutlinedTextField.react');
const InputWrapper = require('../../../../../../../common/react/sidebar/SidebarInputWrapper.react');
const Row = require('../../../../../../../common/react/sidebar/SidebarInputRow.react');
const InvitationSentModal = require('../modals/InvitationSentModal.react');
const modalService = require('../../../../../../../common/react/Modal/ModalManager/services/ModalService');
const RoundedButton = require('../../../../../../../common/react/Button/RoundedButton.react');
const RevokeInvitationModal = require('../modals/RevokeInvitationModal.react');
const Typography = require('../../../../../../../common/react/Typography/Typography.react');

class EditPendingUser extends React.Component {
  getBody() {
    return (
      <div className="team-sidebar-edit-pending-user team-sidebar__body">
        <Row>
          <InputWrapper>
            <OutlinedTextField
              id="user.email"
              name="user.email"
              label="Email"
              required
              readOnly
              value={this.props.user.email} />
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <OutlinedTextField
              id="user.level"
              name="user.level"
              label="Level"
              required
              readOnly
              value={this.props.user.level} />
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <OutlinedTextField
              id="user.role"
              name="user.role"
              label="Role"
              required
              readOnly
              value={this.props.user.role} />
          </InputWrapper>
        </Row>
        <Row>
          <div className="team-sidebar__field">
            <Typography
              uppercase
              color={Typography.COLORS.DUSTY_GRAY}
              weight={Typography.WEIGHTS.BOLD}
              component={Typography.COMPONENTS.H3}
              size={Typography.SIZES.PX11}>
              Invite
            </Typography>
          </div>
        </Row>
        <Row>
          <div className="team-sidebar__field team-sidebar-edit-pending-user__date">
            <InputWrapper>
              <Typography
                color={Typography.COLORS.DUSTY_GRAY}
                component={Typography.COMPONENTS.P}
                size={Typography.SIZES.PX12}>
                Invited on
              </Typography>
              <Typography
                component={Typography.COMPONENTS.P}
                size={Typography.SIZES.PX12}>
                { this.props.user.sent }
              </Typography>
              <Typography
                color={Typography.COLORS.DUSTY_GRAY}
                component={Typography.COMPONENTS.P}
                size={Typography.SIZES.PX11}>
                The date the user was invited last time
              </Typography>
            </InputWrapper>
          </div>
        </Row>
        <Row>
          <div className="team-sidebar__field team-sidebar-edit-pending-user__invitation">
            <RoundedButton
              onClick={this.revokeInvitation.bind(this, this.props.user)}>
              Revoke Invitation
            </RoundedButton>
            <RoundedButton
              onClick={this.resendInvitation.bind(this, this.props.user)}
              disabled={this.props.waitingFor.includes('invite-request')}>
              Resend Invitation
            </RoundedButton>
          </div>
        </Row>
      </div>
    );
  }

  revokeInvitation(user) {
    modalService.openModal(
      <RevokeInvitationModal
        user={user}
        onSuccess={this.props.onCloseClick} />,
    );
  }

  resendInvitation(user) {
    this.props.inviteUser({
      email: user.email,
      role: user.role,
      level: user.level,
    }).done(() => {
      modalService.openModal(<InvitationSentModal email={user.email} />);
    });
  }

  render() {
    return (
      <Sidebar
        title="Pending invitation"
        body={this.getBody()}
        onClose={this.props.onCloseClick} />
    );
  }
}

module.exports = connect((state) => ({
  waitingFor: state.waitingFor,
}), {
  inviteUser: actions.inviteUser,
})(EditPendingUser);
