'use strict';

/* eslint-disable no-shadow */

Wethod.module('SettingsApp.Company', function (Company, Wethod, Backbone, Marionette, $) {
  // LEVELS
  this.LevelModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/levels/',
  });

  this.LevelCollection = Wethod.Utility.BaseCollection.extend({
    model: Company.LevelModel,
    url: APIURL + '/levels/',
  });

  // MULTICURRENCY
  this.MulticurrencyModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/authorization/settings/multicurrency',
  });

  // CURRENCIES
  this.CurrencyModel = Wethod.Utility.BaseModel.extend({});

  this.CurrencyCollection = Wethod.Utility.BaseCollection.extend({
    model: Company.CurrencyModel,
    url: APIURL + '/currency/',
  });

  // PROJECT TYPE GROUPS
  this.ProjectTypeGroupModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/project-type-group/',
  });

  this.ProjectTypeGroupCollection = Wethod.Utility.BaseCollection.extend({
    model: Company.ProjectTypeGroupModel,
    url: APIURL + '/project-type-group/',
  });

  // PROJECT TYPES
  this.ProjectTypeModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/projecttype/',
  });

  this.ProjectTypeCollection = Wethod.Utility.BaseCollection.extend({
    model: Company.ProjectTypeModel,
    comparator: 'sort',
    url: APIURL + '/projecttype/',
  });

  // CONTACTS
  this.ContactModel = Backbone.Model.extend({
    defaults: {
      email: null,
      alias: null,
    },
    parse: function (response) {
      var positiveCodes = '200,201';
      var comingFromCollection = response.code === undefined;
      if (comingFromCollection) {
        return response;
      }
      var isError = positiveCodes.indexOf(response.code) === -1;

      if (!isError && this.isNew() && response.data.id) {
        return { id: response.data.id };
      }
      return null;
    },
  });

  this.InvoiceContactModel = Company.ContactModel.extend({
    urlRoot: APIURL + '/invoiceboard/contact',
  });
  this.InvoiceContactCollection = Wethod.Utility.BaseCollection.extend({
    model: Company.InvoiceContactModel,
    url: APIURL + '/invoiceboard/contact',
  });

  this.OrderContactModel = Company.ContactModel.extend({
    urlRoot: APIURL + '/orderboard/contact',
  });
  this.OrderContactCollection = Wethod.Utility.BaseCollection.extend({
    model: Company.OrderContactModel,
    url: APIURL + '/orderboard/contact',
  });

  this.TravelContactModel = Company.ContactModel.extend({
    urlRoot: APIURL + '/travel/contact',
  });
  this.TravelContactCollection = Wethod.Utility.BaseCollection.extend({
    model: Company.TravelContactModel,
    url: APIURL + '/travel/contact',
  });

  // MODAL
  this.LevelModal = Wethod.Utility.BaseModel.extend({});

  this.ProjectModel = Wethod.Utility.BaseModel.extend({});

  this.ProjectCollection = Wethod.Utility.BaseCollection.extend({
    model: Company.ProjectModel,
    url: APIURL + '/pipeline?archived=all',
  });

  // Project label
  this.ProjectLabelModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/projectlabel/',
    initialize: function () {
      this.on('destroy', function () {
        this.collection.trigger('project:label:deleted');
      }, this);
    },
  });
  this.ProjectLabelCollection = Wethod.Utility.BaseCollection.extend({
    url: APIURL + '/projectlabel/',
    model: Company.ProjectLabelModel,
    comparator: function (model) {
      return model.get('sort');
    },
  });

  // PIPELINE
  this.PipelineContact = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/authorization/settings/pipeline/contact',
  });

  this.PipelineRequiredJobOrder = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/authorization/settings/pipeline/job-order',
  });

  this.WastedHoursSettings = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/authorization/settings/wasted',
  });

  this.PipelineValueAsUnit = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/authorization/settings/pipeline/value-as-unit',
  });

  this.GoogleDriveIntegrationConfig = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/google-drive/canvas/config',
  });

  this.PipelineReviewConfig = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/authorization/settings/pipeline/review',
  });

  // Project review criteria
  this.ProjectReviewCriteriaModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/review-criteria/project',
  });

  this.ProjectReviewCriteriaCollection = Wethod.Utility.BaseCollection.extend({
    model: Company.ProjectReviewCriteriaModel,
    url: APIURL + '/review-criteria/project',
  });

  this.TeamReviewCriteriaModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/review-criteria/team',
  });

  this.TeamReviewCriteriaCollection = Wethod.Utility.BaseCollection.extend({
    model: Company.TeamReviewCriteriaModel,
    url: APIURL + '/review-criteria/team',
  });

  this.DetailedTimesheetModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/authorization/settings/timetracking/strategy',
  });

  this.ProjectStatusRiskModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/project-status-risk/',
  });
  this.ProjectStatusRisksCollection = Wethod.Utility.BaseCollection.extend({
    model: Company.ProjectStatusRiskModel,
    url: APIURL + '/project-status-risk/',
  });

  // CASCADING PROJECT STATUS
  this.CascadingProjectStatusModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/authorization/settings/project-status/cascading',
  });

  // PLANNING
  this.PlanningModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/planningboard/over-limit',
  });

  // PLANNING INTERNATIONAL CALENDAR
  this.PlanningInternationlCalendarModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/authorization/settings/planning/international-calendar',
  });

  // Fte
  this.FteModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/fte',
  });

  // METADATA
  this.MetadataModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/metadata/',
  });

  this.MetadataCollection = Wethod.Utility.BaseCollection.extend({
    model: Company.MetadataModel,
    url: APIURL + '/metadata/',
  });

  this.ArchiveStrategyModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/pipeline/archive-strategy',
  });

  this.WonLostModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/pipeline/won-lost',
  });

  // Won/lost Feedback
  this.WonLostFeedbackModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/reason-why/',
  });

  this.WonLostFeedbackCollection = Wethod.Utility.BaseCollection.extend({
    model: Company.WonLostFeedbackModel,
    url: APIURL + '/reason-why/',
  });

  // Won/lost Feedback related projects
  this.FeedbackProjectModel = Wethod.Utility.BaseModel.extend({});

  this.FeedbackProjectCollection = Wethod.Utility.BaseCollection.extend({
    model: Company.FeedbackProjectModel,
  });

  this.WonModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/pipeline/send-on-won',
  });

  this.LostModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/pipeline/send-on-lost',
  });

  // Supplier status
  this.SupplierStatusModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/supplier-status/',
  });

  this.SupplierStatusCollection = Wethod.Utility.BaseCollection.extend({
    model: Company.SupplierStatusModel,
    url: APIURL + '/supplier-status/',
  });

  this.SupplierStatusEnabledModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/authorization/settings/supplier/status',
  });
  // Supplier category
  this.SupplierTagCategoryModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/supplier-tag-category/',
  });

  this.SupplierTagCategoryCollection = Wethod.Utility.BaseCollection.extend({
    model: Company.SupplierTagCategoryModel,
    url: APIURL + '/supplier-tag-category/',
  });
  // Supplier tag
  this.SupplierTagModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/supplier-tag/',
  });

  this.SupplierTagCollection = Wethod.Utility.BaseCollection.extend({
    model: Company.SupplierTagModel,
    url: APIURL + '/supplier-tag/',
  });

  // Business Units
  this.BusinessUnitEnabledModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/authorization/settings/business-unit',
  });
  this.BusinessUnitDefaultFilterModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/authorization/settings/business-unit-filter',
  });

  this.BusinessUnitModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/business-unit/',
  });

  this.BusinessUnitCollection = Wethod.Utility.BaseCollection.extend({
    model: Company.BusinessUnitModel,
    url: APIURL + '/business-unit/',
  });

  this.BusinessUnitMarkupModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/authorization/settings/payroll-adjustment-markup',
  });

  // Headcount target
  this.HeadcountTargetModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/authorization/settings/headcount-target',
  });

  // Opportunity stage
  this.OpportunityStatusModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/opportunity-status/',
  });

  this.OpportunityStatusCollection = Wethod.Utility.BaseCollection.extend({
    model: Company.OpportunityStatusModel,
    url: APIURL + '/opportunity-status/',
  });

  // EXPENSE CATEGORIES
  this.ExpenseCategoryModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/expense-categories/',
  });

  this.ExpenseCategoryCollection = Wethod.Utility.BaseCollection.extend({
    model: Company.ExpenseCategoryModel,
    url: APIURL + '/expense-categories/',
  });

  // EXPENSE CATEGORY TYPE
  this.ExpenseCategoryTypeModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/expense-categories/reimbursement-types',
  });

  this.ExpenseCategoryTypeCollection = Wethod.Utility.BaseCollection.extend({
    model: Company.ExpenseCategoryModel,
    url: APIURL + '/expense-categories/reimbursement-types',
  });

  // Bank account
  this.BankAccountModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/bank-account/',
  });

  this.BankAccountCollection = Wethod.Utility.BaseCollection.extend({
    model: Company.BankAccountModel,
    url: APIURL + '/bank-account/',
  });

  // VAT rate
  this.VatRateModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/vat-rate/',
  });

  this.VatRateCollection = Wethod.Utility.BaseCollection.extend({
    model: Company.VatRateModel,
    url: APIURL + '/vat-rate/',
  });

  // Payment term
  this.PaymentTermModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/payment-term/',
  });

  this.PaymentTermCollection = Wethod.Utility.BaseCollection.extend({
    model: Company.PaymentTermModel,
    url: APIURL + '/payment-term/',
  });

  // Modal
  this.Modal = Backbone.Model.extend();

  var API = {
    getLevels: function () {
      var levels = new Company.LevelCollection();
      var defer = $.Deferred();

      levels.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    saveLevel: function (levelModel) {
      var defer = $.Deferred();

      levelModel.save(null, {
        success: function (model) {
          defer.resolve(model);
        },
        error: function (response) {
          defer.resolve(response);
        },
        wait: true,
      });

      return defer.promise();
    },
    deleteLevel: function (levelModel) {
      var defer = $.Deferred();

      levelModel.destroy({
        success: function (model) {
          defer.resolve(model);
        },
        error: function (response) {
          defer.resolve(response);
        },
        wait: true,
      });

      return defer.promise();
    },
    getMulticurrency: function () {
      var multicurrency = new Company.MulticurrencyModel();
      var defer = $.Deferred();

      multicurrency.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    setMulticurrency: function (isMulticurrency) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/authorization/settings/multicurrency?is_multicurrency=' + isMulticurrency,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
    getCurrencies: function () {
      var currencies = new Company.CurrencyCollection();
      var defer = $.Deferred();

      currencies.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    promoteCurrency: function (id) {
      var defer = $.Deferred();
      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/currency/' + id + '/promote',
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
    getProjectTypeGroups: function () {
      var projectTypeGroups = new Company.ProjectTypeGroupCollection();
      var defer = $.Deferred();

      projectTypeGroups.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    createProjectTypeGroup: function (data) {
      var model = new Company.ProjectTypeGroupModel(data);
      var defer = $.Deferred();

      model.save(null, {
        wait: true,
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    updateProjectTypeGroup: function (model) {
      var defer = $.Deferred();

      model.save(null, {
        wait: true,
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    deleteProjectTypeGroup: function (model) {
      var defer = $.Deferred();

      model.destroy({
        success: function (model) {
          defer.resolve(model);
        },
        error: function (response) {
          defer.resolve(response);
        },
        wait: true,
      });

      return defer.promise();
    },
    updateProjectTypeGroupDefault: function (defaultId) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/project-type-group/default/' + defaultId,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
    getProjectTypes: function () {
      var projectTypes = new Company.ProjectTypeCollection();
      var defer = $.Deferred();

      projectTypes.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    saveProjectType: function (projectTypeModel, force) {
      var defer = $.Deferred();

      if (force) {
        projectTypeModel.url = projectTypeModel.url() + '?force=true';
      }

      projectTypeModel.save(null, {
        success: function (model, response) {
          if (response.code === 200 || response.code === 201) {
            defer.resolve(model);
          } else {
            defer.reject(response);
          }
        },
        error: function (response) {
          defer.resolve(response);
        },
        wait: true,
      });

      return defer.promise();
    },
    deleteProjectType: function (oldTPId, newPTId) {
      var defer = $.Deferred();
      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/projecttype/change',
        xhrFields: {
          withCredentials: true,
        },
        data: {
          old_id: oldTPId,
          new_id: newPTId,
        },
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
    sortProjectLabel: function (data) {
      var defer = $.Deferred();
      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/projectlabel/sort',
        xhrFields: {
          withCredentials: true,
        },
        data: JSON.stringify(data),
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
    sortProjectType: function (data) {
      var defer = $.Deferred();
      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/projecttype/sort',
        xhrFields: {
          withCredentials: true,
        },
        data: JSON.stringify(data),
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
    getProjects: function (projectTypeName) {
      var projectTypes = new Company.ProjectCollection();
      var defer = $.Deferred();

      projectTypes.url = projectTypes.url + '&job-order-category=' + projectTypeName;

      projectTypes.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    getInvoice: function () {
      var invoiceContact = new Company.InvoiceContactCollection();
      var defer = $.Deferred();

      invoiceContact.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    saveInvoice: function (model) {
      var defer = $.Deferred();

      model.save(null, {
        success: function (data, response) {
          if (response.code === 400) {
            defer.reject(response.message);
          }
          defer.resolve(response.data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    deleteInvoice: function (model) {
      var defer = $.Deferred();

      model.destroy({
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    getOrder: function () {
      var orderContact = new Company.OrderContactCollection();
      var defer = $.Deferred();

      orderContact.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    saveOrder: function (model) {
      var defer = $.Deferred();

      model.save(null, {
        success: function (data, response) {
          if (response.code === 400) {
            defer.reject(response.message);
          }
          defer.resolve(response.data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    deleteOrder: function (model) {
      var defer = $.Deferred();

      model.destroy({
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    getTravels: function () {
      var travelContacts = new Company.TravelContactCollection();
      var defer = $.Deferred();

      travelContacts.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    saveTravel: function (model) {
      var defer = $.Deferred();

      model.save(null, {
        success: function (data, response) {
          if (response.code === 400) {
            defer.reject(response.message);
          }
          defer.resolve(response.data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    deleteTravel: function (model) {
      var defer = $.Deferred();

      model.destroy({
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },

    getProjectLabels: function () {
      var collection = new Company.ProjectLabelCollection();
      var defer = $.Deferred();

      collection.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    insertProjectLabels: function (data) {
      var model = new Company.ProjectLabelModel(data);
      var defer = $.Deferred();

      model.save(null, {
        wait: true,
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    updateProjectLabels: function (model) {
      var defer = $.Deferred();

      model.save(null, {
        wait: true,
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    deleteProjectLabel: function (ProjectLabelModel) {
      var defer = $.Deferred();

      ProjectLabelModel.destroy(null, {
        success: function (model) {
          defer.resolve(model);
        },
        error: function (response) {
          defer.resolve(response);
        },
        wait: true,
      });

      return defer.promise();
    },

    getDetailedTimesheet: function () {
      var timesheet = new Company.DetailedTimesheetModel();
      var defer = $.Deferred();

      timesheet.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },

    updateTimesheetDetailed: function (mode) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/authorization/settings/timetracking/strategy?strategy=' + mode,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },

    getTimesheetEmailFrequency: function () {
      var defer = $.Deferred();

      $.ajax({
        method: 'GET',
        crossDomain: true,
        url: APIURL + '/authorization/settings/timetracking/frequency',
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response.data);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
    getTimesheetTimeLimit: function () {
      var defer = $.Deferred();

      $.ajax({
        method: 'GET',
        crossDomain: true,
        url: APIURL + '/authorization/settings/timetracking/timelimit',
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response.data);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
    getProjectStatusRisks: function () {
      var psModel = new Company.ProjectStatusRisksCollection();
      var defer = $.Deferred();

      psModel.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    createProjectStatusRisk: function (data) {
      var model = new Company.ProjectStatusRiskModel(data);
      var defer = $.Deferred();

      model.save(null, {
        wait: true,
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    updateProjectStatusRisk: function (model) {
      var defer = $.Deferred();

      model.save(null, {
        wait: true,
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    deleteProjectStatusRisk: function (model) {
      var defer = $.Deferred();

      model.destroy({
        success: function (model) {
          defer.resolve(model);
        },
        error: function (response) {
          defer.resolve(response);
        },
        wait: true,
      });

      return defer.promise();
    },
    updateProjectStatusDefaultRisk: function (defaultId) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/project-status-risk/set-default/' + defaultId,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },

    getCascadingProjectStatus: function () {
      var psModel = new Company.CascadingProjectStatusModel();
      var defer = $.Deferred();

      psModel.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },

    setCascadingProjectStatus: function (mode) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/authorization/settings/project-status/cascading?mode=' + mode,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },

    getPipelineContact: function () {
      var timesheet = new Company.PipelineContact();
      var defer = $.Deferred();

      timesheet.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },

    updatePipelineContact: function (mode) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/authorization/settings/pipeline/contact?required=' + mode,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },

    getWastedHoursSettings: function () {
      var model = new Company.WastedHoursSettings();
      var defer = $.Deferred();

      model.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },

    updateWastedHoursEnabled: function (enabled) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/authorization/settings/wasted?enabled=' + enabled,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },

    getPipelineRequiredJobOrder: function () {
      var requiredJobOrder = new Company.PipelineRequiredJobOrder();
      var defer = $.Deferred();

      requiredJobOrder.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },

    updatePipelineRequiredJobOrder: function (mode) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/authorization/settings/pipeline/job-order?required=' + mode,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },

    getPipelineValueAsUnit: function () {
      var valueAsUnit = new Company.PipelineValueAsUnit();
      var defer = $.Deferred();

      valueAsUnit.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },

    updatePipelineValueAsUnit: function (enabled) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/authorization/settings/pipeline/value-as-unit?enabled=' + enabled,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },

    getProjectReviewCriteria: function () {
      var model = new Company.ProjectReviewCriteriaCollection();
      var defer = $.Deferred();

      model.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    insertProjectReviewCriteria: function (data) {
      var model = new Company.ProjectReviewCriteriaModel(data);
      var defer = $.Deferred();

      model.save(null, {
        wait: true,
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    updateProjectReviewCriteria: function (model) {
      var defer = $.Deferred();

      model.save(null, {
        wait: true,
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    deleteProjectReviewCriteria: function (model) {
      var defer = $.Deferred();

      model.destroy({
        success: function (model) {
          defer.resolve(model);
        },
        error: function (response) {
          defer.resolve(response);
        },
        wait: true,
      });

      return defer.promise();
    },
    getTeamReviewCriteria: function () {
      var model = new Company.TeamReviewCriteriaCollection();
      var defer = $.Deferred();

      model.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    insertTeamReviewCriteria: function (data) {
      var model = new Company.TeamReviewCriteriaModel(data);
      var defer = $.Deferred();

      model.save(null, {
        wait: true,
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    updateTeamReviewCriteria: function (model) {
      var defer = $.Deferred();

      model.save(null, {
        wait: true,
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    deleteTeamReviewCriteria: function (model) {
      var defer = $.Deferred();

      model.destroy({
        success: function (model) {
          defer.resolve(model);
        },
        error: function (response) {
          defer.resolve(response);
        },
        wait: true,
      });

      return defer.promise();
    },

    getPipelineReviewConfig: function () {
      var requiredReview = new Company.PipelineReviewConfig();
      var defer = $.Deferred();

      requiredReview.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    updatePipelineReviewConfig: function (model) {
      var defer = $.Deferred();

      model.save(null, {
        wait: true,
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },

    editPipelineReviewConfigJoc: function (options) {
      var defer = $.Deferred();

      $.ajax({
        method: 'PUT',
        crossDomain: true,
        url: APIURL + '/projecttype/request-review/' + options.id + '?value=' + options.from_value,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },

    getGoogleDriveIntegrationConfig: function () {
      var requiredReview = new Company.GoogleDriveIntegrationConfig();
      var defer = $.Deferred();

      requiredReview.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },

    getPlanning: function () {
      var planning = new Company.PlanningModel();
      var defer = $.Deferred();

      planning.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },

    updatePlanning: function (checked) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/planningboard/over-limit?mode=' + checked,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },

    getPlanningInternationalCalendar: function () {
      var planning = new Company.PlanningInternationlCalendarModel();
      var defer = $.Deferred();

      planning.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },

    getFte: function () {
      var planning = new Company.FteModel();
      var defer = $.Deferred();

      planning.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },

    updatePlanningInternationalCalendar: function (options) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/authorization/settings/planning/international-calendar?enabled=' + options.checked + '&highlight_weekend=' + options.highlight_weekends,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },

    getMetadata: function () {
      var metadata = new Company.MetadataCollection();
      var defer = $.Deferred();

      metadata.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    createMetadata: function (model) {
      var defer = $.Deferred();

      model.save(null, {
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
        wait: true,
      });

      return defer.promise();
    },
    updateMetadata: function (model) {
      var defer = $.Deferred();

      model.save(null, {
        wait: true,
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });

      return defer.promise();
    },
    deleteMetadata: function (model) {
      var defer = $.Deferred();

      model.destroy({
        success: function (model) {
          defer.resolve(model);
        },
        error: function () {
          defer.resolve(undefined);
        },
        wait: true,
      });

      return defer.promise();
    },
    getArchiveStrategy: function () {
      var archiveStrategyModel = new Company.ArchiveStrategyModel();
      var defer = $.Deferred();

      archiveStrategyModel.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    setArchiveStrategy: function (archiveStrategyModel) {
      var defer = $.Deferred();
      archiveStrategyModel.save(null, {
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
        wait: true,
      });

      return defer.promise();
    },
    getWonLostContact: function () {
      var archiveStrategyModel = new Company.WonLostModel();
      var defer = $.Deferred();

      archiveStrategyModel.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },

    setWonContact: function (wonModel) {
      var defer = $.Deferred();
      wonModel.save(null, {
        success: function (data, response) {
          defer.resolve(response);
        },
        error: function (response) {
          defer.resolve(response);
        },
        wait: true,
      });

      return defer.promise();
    },
    setLostContact: function (lostModel) {
      var defer = $.Deferred();
      lostModel.save(null, {
        success: function (data, response) {
          defer.resolve(response);
        },
        error: function (response) {
          defer.resolve(response);
        },
        wait: true,
      });

      return defer.promise();
    },
    getWonLostFeedback: function () {
      var wonLostFeedback = new Company.WonLostFeedbackCollection();
      var defer = $.Deferred();

      wonLostFeedback.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    insertWonLostFeedback: function (data) {
      var model = new Company.WonLostFeedbackModel(data);
      var defer = $.Deferred();

      model.save(null, {
        wait: true,
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    updateWonLostFeedback: function (model) {
      var defer = $.Deferred();

      model.save(null, {
        wait: true,
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    getWonLostFeedbackUsage: function (id) {
      var defer = $.Deferred();
      $.ajax({
        method: 'GET',
        crossDomain: true,
        url: APIURL + '/reason-why/' + id,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        var projects = new Company.FeedbackProjectCollection(response.data);
        defer.resolve(projects);
      });

      return defer.promise();
    },
    deleteWonLostFeedback: function (model) {
      var defer = $.Deferred();

      model.destroy({
        success: function (model) {
          defer.resolve(model);
        },
        error: function (response) {
          defer.resolve(response);
        },
        wait: true,
      });

      return defer.promise();
    },
    getSupplierStatusEnabled: function () {
      var supplierStatusEnabled = new Company.SupplierStatusEnabledModel();
      var defer = $.Deferred();

      supplierStatusEnabled.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    updateSupplierStatusEnabled: function (enabled) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/authorization/settings/supplier/status?enabled=' + enabled,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        var model = new Company.SupplierStatusEnabledModel(response.data);
        defer.resolve(model);
      });

      return defer.promise();
    },
    getSupplierStatus: function () {
      var model = new Company.SupplierStatusCollection();
      var defer = $.Deferred();

      model.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    insertSupplierStatus: function (data) {
      var model = new Company.SupplierStatusModel(data);
      var defer = $.Deferred();

      model.save(null, {
        wait: true,
        success: function (responseData) {
          defer.resolve(responseData);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    updateSupplierStatus: function (model) {
      var defer = $.Deferred();

      model.save(null, {
        wait: true,
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    deleteSupplierStatus: function (model, force) {
      var defer = $.Deferred();

      if (force) {
        var id = model.get('id');
        $.ajax({
          method: 'DELETE',
          crossDomain: true,
          url: APIURL + /supplier-status/ + id + '?force=true',
          xhrFields: {
            withCredentials: true,
          },
        }).done(function (response) {
          defer.resolve(response);
        });
      } else {
        model.destroy({
          success: function (responseData, response) {
            defer.resolve(response);
          },
          error: function (response) {
            defer.resolve(response);
          },
        });
      }

      return defer.promise();
    },
    getSupplierTagCategory: function () {
      var model = new Company.SupplierTagCategoryCollection();
      var defer = $.Deferred();

      model.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    insertSupplierTagCategory: function (data) {
      var model = new Company.SupplierTagCategoryModel(data);
      var defer = $.Deferred();

      model.save(null, {
        wait: true,
        success: function (responseData) {
          defer.resolve(responseData);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    updateSupplierTagCategory: function (model) {
      var defer = $.Deferred();

      model.save(null, {
        wait: true,
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    deleteSupplierTagCategory: function (model) {
      var defer = $.Deferred();

      model.destroy({
        success: function (responseData, response) {
          defer.resolve(response);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    getSupplierTag: function () {
      var model = new Company.SupplierTagCollection();
      var defer = $.Deferred();

      model.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    insertSupplierTag: function (data) {
      var model = new Company.SupplierTagModel(data);
      var defer = $.Deferred();

      model.save(null, {
        wait: true,
        success: function (responseData) {
          defer.resolve(responseData);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    updateSupplierTag: function (model) {
      var defer = $.Deferred();

      model.save(null, {
        wait: true,
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    deleteSupplierTag: function (model) {
      var defer = $.Deferred();

      model.destroy({
        success: function (model, response) {
          defer.resolve(response);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    getBusinessUnitEnabled: function () {
      var model = new Company.BusinessUnitEnabledModel();
      var defer = $.Deferred();

      model.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    updateBusinessUnitEnabled: function (enabled) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/authorization/settings/business-unit?enabled=' + enabled,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        var model = new Company.BusinessUnitEnabledModel(response.data);
        defer.resolve(model);
      });

      return defer.promise();
    },
    getBusinessUnitDefaultFilter: function () {
      var model = new Company.BusinessUnitDefaultFilterModel();
      var defer = $.Deferred();

      model.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    updateBusinessUnitDefaultFilter: function (userBUFilter) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/authorization/settings/business-unit-filter?filterByUserBU=' + userBUFilter,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        var model = new Company.BusinessUnitDefaultFilterModel(response.data);
        defer.resolve(model);
      });

      return defer.promise();
    },
    updateBusinessUnitMarkup: function (markup) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/authorization/settings/payroll-adjustment-markup?value=' + markup,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        var model = new Company.BusinessUnitDefaultFilterModel(response.data);
        defer.resolve(model);
      });

      return defer.promise();
    },
    getBusinessUnit: function () {
      var model = new Company.BusinessUnitCollection();
      var defer = $.Deferred();

      model.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    getBusinessUnitMarkup: function () {
      var model = new Company.BusinessUnitMarkupModel();
      var defer = $.Deferred();

      model.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    insertBusinessUnit: function (data) {
      var model = new Company.BusinessUnitModel(data);
      var defer = $.Deferred();

      model.save(null, {
        wait: true,
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    updateBusinessUnit: function (model) {
      var defer = $.Deferred();

      model.save(null, {
        wait: true,
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    deleteBusinessUnit: function (model, force) {
      var defer = $.Deferred();
      var forceString = force ? '?force=true' : '';

      var id = model.get('id');
      $.ajax({
        method: 'DELETE',
        crossDomain: true,
        url: APIURL + '/business-unit/' + id + forceString,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
    setHeadcountTarget: function (data) {
      var model = new Company.HeadcountTargetModel(data);

      var defer = $.Deferred();

      model.save(null, {
        wait: true,
        success: function (responseData) {
          defer.resolve(responseData);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    getOpportunityStatus: function () {
      var model = new Company.OpportunityStatusCollection();
      var defer = $.Deferred();

      model.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    createOpportunityStatus: function (data) {
      var model = new Company.OpportunityStatusModel(data);
      var defer = $.Deferred();

      model.save(null, {
        wait: true,
        success: function (responseData) {
          defer.resolve(responseData);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    updateOpportunityStatus: function (model, changes) {
      // PATCH update
      var defer = $.Deferred();

      model.save(changes, {
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
        wait: true,
        patch: true,
      });

      return defer.promise();
    },
    deleteOpportunityStatus: function (model, force) {
      var defer = $.Deferred();

      if (force) {
        var id = model.get('id');
        $.ajax({
          method: 'DELETE',
          crossDomain: true,
          url: APIURL + /opportunity-status/ + id + '?force=true',
          xhrFields: {
            withCredentials: true,
          },
        }).done(function (response) {
          defer.resolve(response);
        });
      } else {
        model.destroy({
          success: function (responseData, response) {
            defer.resolve(response);
          },
          error: function (response) {
            defer.resolve(response);
          },
        });
      }

      return defer.promise();
    },
    getExpenseCategory: function (includeArchived) {
      var model = new Company.ExpenseCategoryCollection();
      var defer = $.Deferred();

      includeArchived = includeArchived ? 'true' : 'false';
      var includeArchivedParam = '?includeArchived=' + includeArchived;
      model.url = APIURL + '/expense-categories/' + includeArchivedParam;

      model.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    createExpenseCategory: function (data) {
      var model = new Company.ExpenseCategoryModel(data);
      var defer = $.Deferred();

      model.save(null, {
        wait: true,
        success: function (responseData) {
          defer.resolve(responseData);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    archiveExpenseCategory: function (model, archive) {
      var defer = $.Deferred();

      var id = model.get('id');
      var data = { archive: archive };

      $.ajax({
        method: 'PUT',
        crossDomain: true,
        url: APIURL + /expense-categories/ + id + '/archive',
        xhrFields: {
          withCredentials: true,
        },
        data: JSON.stringify(data),
      }).done(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
    getExpenseCategoryType: function () {
      var model = new Company.ExpenseCategoryTypeCollection();
      var defer = $.Deferred();

      model.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    getVatRate: function (includeArchived) {
      var model = new Company.VatRateCollection();
      var defer = $.Deferred();

      includeArchived = includeArchived ? 'true' : 'false';
      var includeArchivedParam = '?includeArchived=' + includeArchived;
      model.url += includeArchivedParam;

      model.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    insertVatRate: function (data) {
      var model = new Company.VatRateModel(data);
      var defer = $.Deferred();

      model.save(null, {
        wait: true,
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    updateVatRate: function (model, changes) {
      // PATCH UPDATE
      var defer = $.Deferred();

      model.save(changes, {
        wait: true,
        patch: true,
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    getBankAccount: function (includeArchived) {
      var model = new Company.BankAccountCollection();
      var defer = $.Deferred();

      includeArchived = includeArchived ? 'true' : 'false';
      var includeArchivedParam = '?includeArchived=' + includeArchived;
      model.url += includeArchivedParam;

      model.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    insertBankAccount: function (data) {
      var model = new Company.BankAccountModel(data);
      var defer = $.Deferred();

      model.save(null, {
        wait: true,
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    archiveBankAccount: function (model, changes) {
      // PATCH UPDATE
      var defer = $.Deferred();

      model.save(changes, {
        wait: true,
        patch: true,
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    getPaymentTerm: function (includeArchived) {
      var model = new Company.PaymentTermCollection();
      var defer = $.Deferred();

      includeArchived = includeArchived ? 'true' : 'false';
      var includeArchivedParam = '?includeArchived=' + includeArchived;
      model.url += includeArchivedParam;

      model.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    insertPaymentTerm: function (data) {
      var model = new Company.PaymentTermModel(data);
      var defer = $.Deferred();

      model.save(null, {
        wait: true,
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    updatePaymentTerm: function (model, changes) {
      // PATCH UPDATE
      var defer = $.Deferred();

      model.save(changes, {
        wait: true,
        patch: true,
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
    getCountries: function () {
      var defer = $.Deferred();
      $.ajax({
        method: 'GET',
        crossDomain: true,
        url: APIURL + '/countries/',
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response.data);
      });

      return defer.promise();
    },
    getProvinces: function () {
      var defer = $.Deferred();
      $.ajax({
        method: 'GET',
        crossDomain: true,
        url: APIURL + '/provinces/',
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response.data);
      });

      return defer.promise();
    },

    getJobOrderTemplateEnabled: function () {
      var defer = $.Deferred();

      $.ajax({
        method: 'GET',
        crossDomain: true,
        url: APIURL + '/authorization/settings/auto-job-order',
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        if (response) {
          defer.resolve(response.data);
        } else {
          defer.resolve({});
        }
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },

    getJobOrderTemplateList: function () {
      var defer = $.Deferred();

      $.ajax({
        method: 'GET',
        crossDomain: true,
        url: APIURL + '/autojoborder/templates',
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        if (response && response.data) {
          defer.resolve(response.data);
        } else {
          defer.resolve([]);
        }
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
    getOrderPlanSettings: function () {
      var defer = $.Deferred();

      $.ajax({
        method: 'GET',
        crossDomain: true,
        url: APIURL + '/authorization/settings/order-view',
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        if (response && response.data) {
          defer.resolve(response.data);
        } else {
          defer.resolve([]);
        }
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },

  };

  // LEVELS
  Wethod.reqres.setHandler('get:company:levels', function () {
    return API.getLevels();
  });

  Wethod.reqres.setHandler('save:company:level', function (levelModel) {
    return API.saveLevel(levelModel);
  });

  Wethod.reqres.setHandler('delete:company:level', function (levelModel) {
    return API.deleteLevel(levelModel);
  });

  // MULTICURRENCY
  Wethod.reqres.setHandler('get:company:multicurrency', function () {
    return API.getMulticurrency();
  });
  Wethod.reqres.setHandler('set:company:multicurrency', function (isMulticurrency) {
    return API.setMulticurrency(isMulticurrency);
  });

  // CURRENCIES
  Wethod.reqres.setHandler('get:company:currencies', function () {
    return API.getCurrencies();
  });
  Wethod.reqres.setHandler('company:currencies:promote', function (id) {
    return API.promoteCurrency(id);
  });

  // PROJECT TYPE
  Wethod.reqres.setHandler('get:company:projectType', function () {
    return API.getProjectTypes();
  });

  Wethod.reqres.setHandler('save:company:projectType', function (projectTypeModel, force) {
    return API.saveProjectType(projectTypeModel, force);
  });

  Wethod.reqres.setHandler('delete:company:projectType', function (oldPTId, newTPId) {
    return API.deleteProjectType(oldPTId, newTPId);
  });

  Wethod.reqres.setHandler('sort:company:projectLabel', function (data) {
    return API.sortProjectLabel(data);
  });

  Wethod.reqres.setHandler('sort:company:projectType', function (data) {
    return API.sortProjectType(data);
  });

  // PROJECT TYPE GROUPS
  Wethod.reqres.setHandler('get:company:projectTypeGroups', function () {
    return API.getProjectTypeGroups();
  });
  Wethod.reqres.setHandler('create:company:projectTypeGroups', function (data) {
    return API.createProjectTypeGroup(data);
  });
  Wethod.reqres.setHandler('update:company:projectTypeGroups', function (model) {
    return API.updateProjectTypeGroup(model);
  });
  Wethod.reqres.setHandler('delete:company:projectTypeGroups', function (model) {
    return API.deleteProjectTypeGroup(model);
  });
  Wethod.reqres.setHandler('update:company:projectTypeGroups:default', function (defaultId) {
    return API.updateProjectTypeGroupDefault(defaultId);
  });

  // JOB ORDER TEMPLATES
  Wethod.reqres.setHandler('get:company:jobOrderTemplate:enabled', function () {
    return API.getJobOrderTemplateEnabled();
  });
  Wethod.reqres.setHandler('get:company:jobOrderTemplate:list', function () {
    return API.getJobOrderTemplateList();
  });

  // ORDER PLAN VIEW PREFERENCES
  Wethod.reqres.setHandler('get:company:orderPlanSettings', function () {
    return API.getOrderPlanSettings();
  });

  // PROJECTS
  Wethod.reqres.setHandler('get:company:projects', function (projectTypeName) {
    return API.getProjects(projectTypeName);
  });

  // CONTACT
  Wethod.reqres.setHandler('get:company:invoice:contact', function () {
    return API.getInvoice();
  });
  Wethod.reqres.setHandler('save:company:invoice:contact', function (data) {
    return API.saveInvoice(data);
  });
  Wethod.reqres.setHandler('delete:company:invoice:contact', function (model) {
    return API.deleteInvoice(model);
  });
  Wethod.reqres.setHandler('get:company:order:contact', function () {
    return API.getOrder();
  });
  Wethod.reqres.setHandler('save:company:order:contact', function (data) {
    return API.saveOrder(data);
  });
  Wethod.reqres.setHandler('delete:company:order:contact', function (model) {
    return API.deleteOrder(model);
  });
  Wethod.reqres.setHandler('get:company:travel:contacts', function () {
    return API.getTravels();
  });
  Wethod.reqres.setHandler('save:company:travel:contact', function (data) {
    return API.saveTravel(data);
  });
  Wethod.reqres.setHandler('delete:company:travel:contact', function (model) {
    return API.deleteTravel(model);
  });

  Wethod.reqres.setHandler('get:company:timesheet:detailed', function () {
    return API.getDetailedTimesheet();
  });
  Wethod.reqres.setHandler('get:company:timesheet:emailFrequency', function () {
    return API.getTimesheetEmailFrequency();
  });
  Wethod.reqres.setHandler('get:company:timesheet:timelimit', function () {
    return API.getTimesheetTimeLimit();
  });
  // Pipeline
  Wethod.reqres.setHandler('get:company:pipeline:contact', function () {
    return API.getPipelineContact();
  });
  Wethod.reqres.setHandler('set:company:pipeline:contact', function (required) {
    return API.updatePipelineContact(required);
  });
  Wethod.reqres.setHandler('get:company:pipeline:requiredJobOrder', function () {
    return API.getPipelineRequiredJobOrder();
  });
  Wethod.reqres.setHandler('set:company:pipeline:requiredJobOrder', function (required) {
    return API.updatePipelineRequiredJobOrder(required);
  });
  Wethod.reqres.setHandler('get:company:pipeline:review:config', function () {
    return API.getPipelineReviewConfig();
  });
  Wethod.reqres.setHandler('set:company:pipeline:reviewConfig', function (model) {
    return API.updatePipelineReviewConfig(model);
  });
  Wethod.reqres.setHandler('get:company:pipeline:valueAsUnit', function () {
    return API.getPipelineValueAsUnit();
  });
  Wethod.reqres.setHandler('set:company:pipeline:valueAsUnit', function (enabled) {
    return API.updatePipelineValueAsUnit(enabled);
  });

  Wethod.reqres.setHandler('get:company:project:review:criteria', function () {
    return API.getProjectReviewCriteria();
  });
  Wethod.reqres.setHandler('update:company:project:review:criteria', function (model) {
    return API.updateProjectReviewCriteria(model);
  });
  Wethod.reqres.setHandler('create:company:project:review:criteria', function (data) {
    return API.insertProjectReviewCriteria(data);
  });
  Wethod.reqres.setHandler('delete:company:project:review:criteria', function (model) {
    return API.deleteProjectReviewCriteria(model);
  });
  Wethod.reqres.setHandler('get:company:team:review:criteria', function () {
    return API.getTeamReviewCriteria();
  });
  Wethod.reqres.setHandler('update:company:team:review:criteria', function (model) {
    return API.updateTeamReviewCriteria(model);
  });
  Wethod.reqres.setHandler('create:company:team:review:criteria', function (data) {
    return API.insertTeamReviewCriteria(data);
  });
  Wethod.reqres.setHandler('delete:company:team:review:criteria', function (model) {
    return API.deleteTeamReviewCriteria(model);
  });
  Wethod.reqres.setHandler('get:company:integration:gdrive:config', function () {
    return API.getGoogleDriveIntegrationConfig();
  });

  Wethod.reqres.setHandler('get:company:projectLabels', function () {
    return API.getProjectLabels();
  });
  Wethod.reqres.setHandler('insert:company:projectLabels', function (data) {
    return API.insertProjectLabels(data);
  });
  Wethod.reqres.setHandler('update:company:projectLabels', function (model) {
    return API.updateProjectLabels(model);
  });
  Wethod.reqres.setHandler('delete:company:projectLabels', function (model) {
    return API.deleteProjectLabel(model);
  });

  // PLANNING
  Wethod.reqres.setHandler('get:company:planning', function () {
    return API.getPlanning();
  });
  Wethod.reqres.setHandler('update:company:planning', function (checked) {
    return API.updatePlanning(checked);
  });
  Wethod.reqres.setHandler('get:company:planning:calendar', function () {
    return API.getPlanningInternationalCalendar();
  });
  Wethod.reqres.setHandler('get:company:fte', function () {
    return API.getFte();
  });
  Wethod.reqres.setHandler('update:company:planning:calendar', function (options) {
    return API.updatePlanningInternationalCalendar(options);
  });

  // METADATA
  Wethod.reqres.setHandler('get:company:metadata', function () {
    return API.getMetadata();
  });

  Wethod.reqres.setHandler('create:company:metadata', function (model) {
    return API.createMetadata(model);
  });

  Wethod.reqres.setHandler('update:company:metadata', function (model) {
    return API.updateMetadata(model);
  });

  Wethod.reqres.setHandler('delete:company:metadata', function (model) {
    return API.deleteMetadata(model);
  });

  Wethod.reqres.setHandler('get:company:archive:strategy', function () {
    return API.getArchiveStrategy();
  });

  Wethod.reqres.setHandler('set:company:archive:strategy', function (model) {
    return API.setArchiveStrategy(model);
  });

  // WON LOST Project Contact
  Wethod.reqres.setHandler('get:company:won-lost-project:contact', function () {
    return API.getWonLostContact();
  });
  Wethod.reqres.setHandler('set:company:won-project:contact', function (wonModel) {
    return API.setWonContact(wonModel);
  });
  Wethod.reqres.setHandler('set:company:lost-project:contact', function (lostModel) {
    return API.setLostContact(lostModel);
  });

  // Won/Lost Feedback
  Wethod.reqres.setHandler('get:company:wonLostFeedback', function () {
    return API.getWonLostFeedback();
  });
  Wethod.reqres.setHandler('create:company:wonLostFeedback', function (data) {
    return API.insertWonLostFeedback(data);
  });
  Wethod.reqres.setHandler('update:company:wonLostFeedback', function (wonLostFeedbackModel) {
    return API.setLostContact(wonLostFeedbackModel);
  });
  Wethod.reqres.setHandler('delete:company:wonLostFeedback', function (model) {
    return API.deleteWonLostFeedback(model);
  });
  Wethod.reqres.setHandler('get:company:usage:wonLostFeedback', function (id) {
    return API.getWonLostFeedbackUsage(id);
  });

  // Supplier status
  Wethod.reqres.setHandler('get:company:supplier-status:enabled', function () {
    return API.getSupplierStatusEnabled();
  });
  Wethod.reqres.setHandler('set:company:supplier-status:enabled', function (enabled) {
    return API.updateSupplierStatusEnabled(enabled);
  });
  Wethod.reqres.setHandler('get:company:supplier-status', function () {
    return API.getSupplierStatus();
  });
  Wethod.reqres.setHandler('update:company:supplier-status', function (model) {
    return API.updateSupplierStatus(model);
  });
  Wethod.reqres.setHandler('create:company:supplier-status', function (data) {
    return API.insertSupplierStatus(data);
  });
  Wethod.reqres.setHandler('delete:company:supplier-status', function (model, force) {
    return API.deleteSupplierStatus(model, force);
  });
  // Supplier category
  Wethod.reqres.setHandler('get:company:supplier-tag-category', function () {
    return API.getSupplierTagCategory();
  });
  Wethod.reqres.setHandler('update:company:supplier-tag-category', function (model) {
    return API.updateSupplierTagCategory(model);
  });
  Wethod.reqres.setHandler('create:company:supplier-tag-category', function (data) {
    return API.insertSupplierTagCategory(data);
  });
  Wethod.reqres.setHandler('delete:company:supplier-tag-category', function (model) {
    return API.deleteSupplierTagCategory(model);
  });
  // Supplier tag
  Wethod.reqres.setHandler('get:company:supplier-tag', function () {
    return API.getSupplierTag();
  });
  Wethod.reqres.setHandler('update:company:supplier-tag', function (model) {
    return API.updateSupplierTag(model);
  });
  Wethod.reqres.setHandler('create:company:supplier-tag', function (data) {
    return API.insertSupplierTag(data);
  });
  Wethod.reqres.setHandler('delete:company:supplier-tag', function (model) {
    return API.deleteSupplierTag(model);
  });
  // Business Unit
  Wethod.reqres.setHandler('get:company:business-unit:enabled', function () {
    return API.getBusinessUnitEnabled();
  });
  Wethod.reqres.setHandler('set:company:business-unit:enabled', function (enabled) {
    return API.updateBusinessUnitEnabled(enabled);
  });
  Wethod.reqres.setHandler('get:company:business-unit:filter', function () {
    return API.getBusinessUnitDefaultFilter();
  });
  Wethod.reqres.setHandler('set:company:business-unit:filter', function (userBUFilter) {
    return API.updateBusinessUnitDefaultFilter(userBUFilter);
  });
  Wethod.reqres.setHandler('set:company:business-unit:markup', function (markup) {
    return API.updateBusinessUnitMarkup(markup);
  });
  Wethod.reqres.setHandler('get:company:business-unit', function () {
    return API.getBusinessUnit();
  });
  Wethod.reqres.setHandler('update:company:business-unit', function (model) {
    return API.updateBusinessUnit(model);
  });
  Wethod.reqres.setHandler('create:company:business-unit', function (data) {
    return API.insertBusinessUnit(data);
  });
  Wethod.reqres.setHandler('delete:company:business-unit', function (model, force) {
    return API.deleteBusinessUnit(model, force);
  });
  Wethod.reqres.setHandler('get:company:business-unit:markup', function () {
    return API.getBusinessUnitMarkup();
  });
  // Cascade Project Status
  Wethod.reqres.setHandler('get:company:project-status:cascading', function () {
    return API.getCascadingProjectStatus();
  });
  Wethod.reqres.setHandler('get:company:project-status:risks', function () {
    return API.getProjectStatusRisks();
  });
  Wethod.reqres.setHandler('create:company:project-status:risks', function (data) {
    return API.createProjectStatusRisk(data);
  });
  Wethod.reqres.setHandler('update:company:project-status:risks', function (model) {
    return API.updateProjectStatusRisk(model);
  });
  Wethod.reqres.setHandler('delete:company:project-status:risks', function (model) {
    return API.deleteProjectStatusRisk(model);
  });
  Wethod.reqres.setHandler('update:company:project-status:default:risk', function (defaultId) {
    return API.updateProjectStatusDefaultRisk(defaultId);
  });
  Wethod.reqres.setHandler('update:company:project-status:cascading', function (mode) {
    return API.setCascadingProjectStatus(mode);
  });
  Wethod.reqres.setHandler('edit:company:pipeline:review:config:joc', function (options) {
    return API.editPipelineReviewConfigJoc(options);
  });
  Wethod.reqres.setHandler('get:wasted:hours:settings', function () {
    return API.getWastedHoursSettings();
  });
  Wethod.reqres.setHandler('update:wasted:hours:enabled', function (enabled) {
    return API.updateWastedHoursEnabled(enabled);
  });
  // Headcount target
  Wethod.reqres.setHandler('set:company:headcount-target', function (data) {
    return API.setHeadcountTarget(data);
  });
  // Opportunity stage
  Wethod.reqres.setHandler('get:company:opportunity-status', function () {
    return API.getOpportunityStatus();
  });
  Wethod.reqres.setHandler('update:company:opportunity-status', function (model, changes) {
    return API.updateOpportunityStatus(model, changes);
  });
  Wethod.reqres.setHandler('create:company:opportunity-status', function (data) {
    return API.createOpportunityStatus(data);
  });
  Wethod.reqres.setHandler('delete:company:opportunity-status', function (model, force) {
    return API.deleteOpportunityStatus(model, force);
  });
  // Expense category
  Wethod.reqres.setHandler('get:company:expense-category', function (includeArchived) {
    return API.getExpenseCategory(includeArchived);
  });
  Wethod.reqres.setHandler('create:company:expense-category', function (data) {
    return API.createExpenseCategory(data);
  });
  Wethod.reqres.setHandler('archive:company:expense-category', function (model, archive) {
    return API.archiveExpenseCategory(model, archive);
  });
  // Expense category type
  Wethod.reqres.setHandler('get:company:expense-category-type', function () {
    return API.getExpenseCategoryType();
  });
  // Bank account
  Wethod.reqres.setHandler('get:company:bank-account', function (includeArchived) {
    return API.getBankAccount(includeArchived);
  });
  Wethod.reqres.setHandler('create:company:bank-account', function (data) {
    return API.insertBankAccount(data);
  });
  Wethod.reqres.setHandler('archive:company:bank-account', function (model, changes) {
    return API.archiveBankAccount(model, changes);
  });
  // VAT Rate
  Wethod.reqres.setHandler('get:company:vat-rate', function (includeArchived) {
    return API.getVatRate(includeArchived);
  });
  Wethod.reqres.setHandler('update:company:vat-rate', function (model, changes) {
    return API.updateVatRate(model, changes);
  });
  Wethod.reqres.setHandler('create:company:vat-rate', function (data) {
    return API.insertVatRate(data);
  });
  // Payment term
  Wethod.reqres.setHandler('get:company:payment-term', function (includeArchived) {
    return API.getPaymentTerm(includeArchived);
  });
  Wethod.reqres.setHandler('update:company:payment-term', function (model, changes) {
    return API.updatePaymentTerm(model, changes);
  });
  Wethod.reqres.setHandler('create:company:payment-term', function (data) {
    return API.insertPaymentTerm(data);
  });
  // Countries
  Wethod.reqres.setHandler('get:countries', function () {
    return API.getCountries();
  });
  // Provinces
  Wethod.reqres.setHandler('get:provinces', function () {
    return API.getProvinces();
  });
});
