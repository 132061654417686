const React = require('react');
const { connect } = require('react-redux');
const $ = require('jquery');
const moment = require('moment');
const actions = require('../actions');
const Table = require('../../../../../../common/react/Table2/Table2.react');
const TableHead = require('../../../../../../common/react/Table2/TableHead.react');
const TableBody = require('../../../../../../common/react/Table2/TableBody.react');
const ItemsSearch = require('./ItemsSearch.react');
const Loader = require('../../../../../../common/react/Loader/Loader.react');
const TableRow = require('../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../common/react/Table2/TableCell.react');
const OpenSidebarButton = require('../../../../../../common/react/OpenSidebarButton.react');
const RoundedButton = require('../../../../../../common/react/Button/RoundedButton.react');
const sidebarService = require('../../../../../../common/react/sidebar/SidebarManager/services/SidebarService');
const EditUser = require('./sidebar/EditUser.react');
const EditPendingUser = require('./sidebar/EditPendingUser.react');
const RevokeInvitationModal = require('./modals/RevokeInvitationModal.react');
const InvitationSentModal = require('./modals/InvitationSentModal.react');
const modalService = require('../../../../../../common/react/Modal/ModalManager/services/ModalService');
const ToggleArchiveModal = require('./modals/ToggleArchiveModal.react');
const Tooltip = require('../../../../../../common/react/TooltipFixed/Tooltip.react');
const Typography = require('../../../../../../common/react/Typography/Typography.react');

class TeamList extends React.Component {
  static getTableMaxHeight() {
    return window.innerHeight - (Wethod.getHeaderHeight() + $('.wethod-section-actions').outerHeight(true) + 24);
  }

  static toggleArchive(user) {
    modalService.openModal(
      <ToggleArchiveModal user={user} />,
    );
  }

  static revokeInvitation(user) {
    modalService.openModal(<RevokeInvitationModal user={user} />);
  }

  constructor(props) {
    super(props);

    this.state = {
      tableHeight: null,
    };
  }

  componentDidMount() {
    this.updateTableHeight();
    this.props.getLevels();
    this.props.getRoles();
    this.props.getJobTitles();
    this.props.getPricelists();
    this.props.getTagsCategories();
  }

  handleSidebarOpen(user) {
    sidebarService.openSidebar(
      this.props.typeFilter === 1
        ? <EditPendingUser user={user} />
        : <EditUser user={user} />,
    );
  }

  onFilter(keyword) {
    this.props.setFilters({ keyword });
    this.props.getItems();
  }

  getEmptyContent() {
    if (this.props.isWaiting) {
      return <Loader />;
    }
    return false;
  }

  getHeader() {
    if (this.props.typeFilter === 0) {
      return (
        <TableRow>
          <TableCell className="">Name</TableCell>
          <TableCell className="">Surname</TableCell>
          <TableCell className="">Email</TableCell>
          <TableCell className="">Level</TableCell>
          <TableCell className="">Job Title</TableCell>
          <TableCell className="">Role</TableCell>
          <TableCell className="">Price List</TableCell>
          { typeof this.props.team?.[0]?.employee?.location === 'string' && (
            <TableCell className="team-table__cell--shrink">Location</TableCell>
          )}
          { typeof this.props.team?.[0]?.employee?.workHourCapacity === 'string' && (
            <TableCell className="team-table__cell--shrink">Capacity</TableCell>
          )}
          <TableCell className="team-table__cell--shrink">Tag 1</TableCell>
          <TableCell className="team-table__cell--shrink">Tag 2</TableCell>
          <TableCell className="team-table__cell--shrink">ID Code</TableCell>
          <TableCell className="team-table__cell--shrink" />
        </TableRow>
      );
    }
    if (this.props.typeFilter === 1) {
      return (
        <TableRow>
          <TableCell className="">Email</TableCell>
          <TableCell className="">Level</TableCell>
          <TableCell className="">Role</TableCell>
          <TableCell className="">Invited on</TableCell>
          <TableCell className="team-table__cell--shrink" />
          <TableCell className="team-table__cell--shrink" />
          <TableCell className="team-table__cell--shrink" />
        </TableRow>
      );
    }
    return (
      <TableRow>
        <TableCell className="">Email</TableCell>
        <TableCell className="">Level</TableCell>
        <TableCell className="">Role</TableCell>
        <TableCell className="team-table__cell--shrink" />
        <TableCell className="team-table__cell--shrink" />
      </TableRow>
    );
  }

  getList() {
    return this.props.team.map((user) => {
      if (this.props.typeFilter === 0) {
        return (
          <TableRow key={user.person.id}>
            <TableCell className="">{user.person.name}</TableCell>
            <TableCell className="">{user.person.surname}</TableCell>
            <TableCell className="">{user.employee.email}</TableCell>
            <TableCell className="">{user.level.name}</TableCell>
            <TableCell className="">{user.job_title.name}</TableCell>
            <TableCell className="">{user.role.name}</TableCell>
            <TableCell className="">
              { user.price_list?.isExpired ? (
                <Tooltip label="Pricelist Expired">
                  <Typography color={Typography.COLORS.RASPBERRY_RED}>
                    { user.price_list.name }
                  </Typography>
                </Tooltip>
              ) : (
                user.price_list?.name
              )}
            </TableCell>
            { typeof user.employee?.location === 'string' && (
              <TableCell className="team-table__cell--shrink">{user.employee.location}</TableCell>
            )}
            { typeof user.employee?.workHourCapacity === 'string' && (
              <TableCell className="team-table__cell--shrink">{user.employee.workHourCapacity}</TableCell>
            )}
            <TableCell className="team-table__cell--shrink">{user.tags?.[0]?.name}</TableCell>
            <TableCell className="team-table__cell--shrink">{user.tags?.[1]?.name}</TableCell>
            <TableCell className="team-table__cell--shrink">{user.employee.external_ids?.[0]?.external_id}</TableCell>
            <TableCell
              className="team-table__cell--shrink team-table__cell--sticky-end">
              <OpenSidebarButton onClick={this.handleSidebarOpen.bind(this, user)} />
            </TableCell>
          </TableRow>
        );
      }
      if (this.props.typeFilter === 1) {
        return (
          <TableRow key={user.code}>
            <TableCell className="">{user.email}</TableCell>
            <TableCell className="">{user.level}</TableCell>
            <TableCell className="">{user.role}</TableCell>
            <TableCell className="">{moment(user.sent).format('DD/MM/YYYY')}</TableCell>
            <TableCell className="team-table__cell--shrink">
              <RoundedButton
                onClick={TeamList.revokeInvitation.bind(this, user)}>
                Revoke Invitation
              </RoundedButton>
            </TableCell>
            <TableCell className="team-table__cell--shrink">
              <RoundedButton
                onClick={this.resendInvitation.bind(this, user)}
                disabled={this.props.waitingFor.includes('invite-request')}>
                Resend Invitation
              </RoundedButton>
            </TableCell>
            <TableCell className="team-table__cell--shrink team-table__cell--sticky-end">
              <OpenSidebarButton onClick={this.handleSidebarOpen.bind(this, user)} />
            </TableCell>
          </TableRow>
        );
      }
      return (
        <TableRow key={user.person.id}>
          <TableCell className="">{user.employee.email}</TableCell>
          <TableCell className="">{user.level.name}</TableCell>
          <TableCell className="">{user.role.name}</TableCell>
          <TableCell className="team-table__cell--shrink">
            <RoundedButton
              onClick={TeamList.toggleArchive.bind(this, user)}>
              Unarchive
            </RoundedButton>
          </TableCell>
          <TableCell className="team-table__cell--shrink team-table__cell--sticky-end">
            <OpenSidebarButton onClick={this.handleSidebarOpen.bind(this, user)} />
          </TableCell>
        </TableRow>
      );
    });
  }

  getItems() {
    this.props.getItems();
  }

  resendInvitation(user) {
    this.props.inviteUser({
      email: user.email,
      role: user.role,
      level: user.level,
    }).done(() => {
      modalService.openModal(<InvitationSentModal email={user.email} />);
    });
  }

  updateTableHeight() {
    this.setState({ tableHeight: TeamList.getTableMaxHeight() });
  }

  render() {
    return (
      <Table
        maxHeight={this.state.tableHeight}
        search={<ItemsSearch filter={this.onFilter.bind(this)} />}>
        <TableHead>
          {this.getHeader()}
        </TableHead>
        <TableBody
          hasMore={false}
          loadMore={this.getItems.bind(this)}
          empty={this.getEmptyContent()}>
          {this.getList()}
        </TableBody>
      </Table>
    );
  }
}

module.exports = connect((state) => ({
  typeFilter: state.typeFilter,
  isWaiting: state.waitingFor.length > 0,
  waitingFor: state.waitingFor,
  team: state.team,
  levels: state.levels,
  roles: state.roles,
}), {
  getLevels: actions.getLevels,
  getRoles: actions.getRoles,
  getJobTitles: actions.getJobTitles,
  getPricelists: actions.getPricelists,
  getTagsCategories: actions.getTagsCategories,
  getItems: actions.getItems,
  setFilters: actions.setFilters,
  inviteUser: actions.inviteUser,
})(TeamList);
