const React = require('react');
const { connect } = require('react-redux');
const actions = require('../actions');
const SectionHeader = require('../../../../../../common/react/SectionHeader/components/SectionHeader.react');
const NewUserAction = require('./NewUserAction.react');
const TypeSwitcher = require('./TypeSwitcher.react');

const Actions = ({ setFilters, getItems }) => {
  function onTypeFilterClick(typeFilter) {
    setFilters({ typeFilter });
    getItems();
  }

  return (
    <div className="wethod-section-actions">
      <SectionHeader
        current_section="Team"
        helper_url="settings/index/#team"
        big />
      <div className="team__actions">
        <TypeSwitcher handleClick={onTypeFilterClick} />
        <NewUserAction />
      </div>
    </div>
  );
};

module.exports = connect(null, {
  getItems: actions.getItems,
  setFilters: actions.setFilters,
})(Actions);
