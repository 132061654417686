const React = require('react');
const LevelAvailability = require('../../containers/LevelAvailability');
const ShowIf = require('../../../../../../../common/react/ShowIf/ShowIf.react');
const IconButton = require('../../../../../../../common/react/Button/IconButton.react');

/**
 * Shows amount of hours available for planning, for each level of the given project.
 * @type {LevelsAvailability}
 */
module.exports = class LevelsAvailability extends React.Component {
  /**
   * Apply a smooth scroll to the given element.
   * @param {Element} el
   * @param {number} amount
   */
  static scrollLeft(el, amount) {
    el.scrollTo({ left: amount, behavior: 'smooth' });
  }

  constructor(props) {
    super(props);

    this.state = {
      availabilityEl: null,
      canMoveAvailabilitiesBack: false,
      canMoveAvailabilitiesForward: true,
    };

    this.setAvailabilityEl = this.setAvailabilityEl.bind(this);
    this.moveAvailabilitiesForward = this.moveAvailabilitiesForward.bind(this);
    this.moveAvailabilitiesBack = this.moveAvailabilitiesBack.bind(this);
    this.onAvailabilityScroll = this.onAvailabilityScroll.bind(this);
  }

  onAvailabilityScroll() {
    const { availabilityEl } = this.state;
    if (!availabilityEl) {
      return;
    }
    const { scrollLeft } = availabilityEl;
    this.setState({
      canMoveAvailabilitiesBack: scrollLeft > 0,
      canMoveAvailabilitiesForward: scrollLeft + availabilityEl.clientWidth
        < availabilityEl.scrollWidth,
    });
  }

  setAvailabilityEl(el) {
    if (!this.state.availabilityEl) {
      this.setState({ availabilityEl: el });
    }
  }

  /**
   * Return true if availability need to scroll horizontally.
   * @return {boolean}
   */
  availabilityNeedsHorizontalScroll() {
    const { availabilityEl } = this.state;
    if (!availabilityEl) {
      return false;
    }
    return availabilityEl.scrollWidth > availabilityEl.clientWidth;
  }

  /**
   * Show more levels on the left.
   */
  moveAvailabilitiesBack() {
    const amount = -this.state.availabilityEl.clientWidth / 2;
    LevelsAvailability.scrollLeft(this.state.availabilityEl, amount);
  }

  /**
   * Show more levels on the right.
   */
  moveAvailabilitiesForward() {
    const amount = this.state.availabilityEl.clientWidth / 2;
    LevelsAvailability.scrollLeft(this.state.availabilityEl, amount);
  }

  render() {
    const levels = this.props.project.levels
      .map((level) => ({
        ...level,
        available_days: level.total_days - level.planned_days + level.revision_days,
      }));
    const contingency = (levels.reduce((sum, level) => sum + level.total_days, 0)
      * this.props.project.budget.contingency) / 100;
    const usedContingency = levels.reduce((sum, level) => {
      if (contingency && level.available_days < 0) {
        return sum + level.available_days;
      }
      return sum;
    }, 0);
    const levelsFragment = this.props.project.levels.concat({
      id: 'cont',
      total_days: contingency,
      planned_days: 0,
      revision_days: usedContingency,
      short_name: 'contingency',
      order: Number.MAX_SAFE_INTEGER,
    }).map((level) => {
      const days = level.total_days - level.planned_days + level.revision_days;
      return (
        <LevelAvailability key={level.id}
          name={level.short_name}
          days={days} />
      );
    });

    return (
      <div className="planning-people__project-info-availability-container">
        <ShowIf condition={this.availabilityNeedsHorizontalScroll()}>
          <IconButton onClick={this.moveAvailabilitiesBack}
            icon="back"
            border={false}
            margin={false}
            size={IconButton.SIZE_MEDIUM}
            disabled={!this.state.canMoveAvailabilitiesBack} />
        </ShowIf>
        <div className="planning-people__project-info-availability"
          ref={this.setAvailabilityEl}
          onScroll={this.onAvailabilityScroll}>
          {levelsFragment}
        </div>
        <ShowIf condition={this.availabilityNeedsHorizontalScroll()}>
          <IconButton onClick={this.moveAvailabilitiesForward}
            icon="forward"
            border={false}
            margin={false}
            size={IconButton.SIZE_MEDIUM}
            disabled={!this.state.canMoveAvailabilitiesForward} />
        </ShowIf>
      </div>
    );
  }
};
