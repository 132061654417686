/* eslint-disable consistent-return,class-methods-use-this,no-shadow,no-param-reassign */
const constants = require('./constants');

class TeamReducer {
  constructor(state) {
    this.state = {
      ...state,
      typeFilter: 0,
      waitingFor: [], // contains a key for each pending request
      allUsers: [],
      team: [],
      levels: [],
      roles: [],
      jobTitles: [],
      pricelists: [],
      tagsCategories: [],
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.GET_LEVELS_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('get-levels'),
        };
      }
      case constants.GET_LEVELS_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'get-levels'),
          levels: action.levels,
        };
      }
      case constants.GET_ROLES_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('get-roles'),
        };
      }
      case constants.GET_ROLES_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'get-roles'),
          roles: action.roles,
        };
      }
      case constants.GET_JOB_TITLES_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('get-job-titles'),
        };
      }
      case constants.GET_JOB_TITLES_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'get-job-titles'),
          jobTitles: action.jobTitles,
        };
      }
      case constants.GET_PRICELIST_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('get-price-list'),
        };
      }
      case constants.GET_PRICELIST_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'get-price-list'),
          pricelists: action.pricelists,
        };
      }
      case constants.GET_TEAM_REQUEST: {
        return {
          ...state,
          keyword: action.keyword,
          typeFilter: action.typeFilter,
          allUsers: [],
          team: [],
          waitingFor: state.waitingFor.concat('get-team'),
        };
      }
      case constants.GET_TEAM_SUCCESS: {
        return {
          ...state,
          team: action.team,
          allUsers: action.allUsers,
          waitingFor: state.waitingFor.filter((key) => key !== 'get-team'),
        };
      }
      case constants.SEND_INVITE_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('invite-request'),
        };
      }
      case constants.SEND_INVITE_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'invite-request'),
        };
      }
      case constants.SET_FILTERS: {
        return {
          ...state,
          ...action.filters,
        };
      }
      case constants.SET_TAGS_CATEGORIES: {
        return {
          ...state,
          tagsCategories: action.tagsCategories,
        };
      }
      default:
        return state;
    }
  }
}

module.exports = TeamReducer;
