/* eslint-disable eqeqeq,default-case */

'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var Reducer = require('../../../apps/core/modules/settings/account/reducer');
var Store = require('../../../apps/core/modules/settings/account/store');
var ReactLayout = require('../../../apps/core/modules/settings/account/index');
var FeatureService = require('../../../services/FeatureService');

Wethod.module('SettingsApp.Account', function (Account, Wethod, Backbone, Marionette, $, _, Header) {
  var structureTemplate;
  var paymentStructureTemplate;
  var integrationsStructureTemplate;
  var modalTemplate;
  var billingInfoModel;

  var contentLayout;

  // ACCOUNT
  this.updateUserData = function (userInfo, userPassword, view) {
    view.disableSave();
    view.notifyFeedback('Saving...');

    var saveUserRequest = Wethod.request('set:settings:userInfo', userInfo);
    var resetPasswordRequest = null;
    if (userPassword.old_password || userPassword.new_password_first
      || userPassword.new_password_second) {
      resetPasswordRequest = Wethod.request('set:settings:userPassword', userPassword);
    }

    $.when(saveUserRequest, resetPasswordRequest)
      .done(function (personInfoModelResponse, resetPasswordResponse) {
        view.enableSave();
        if (resetPasswordResponse && resetPasswordResponse.code != 200) {
          view.notifyError(resetPasswordResponse.message);
        } else if (personInfoModelResponse.code != 200) {
          view.notifyError('Unable to save user info');
        } else {
          view.removeNotification();
        }
      });
  };

  // PAYMENT
  /**
   * [restituisce il numero della carta di credito son sole le ultime 4 cifre visibili]
   * @param  {[String]} brand [tipo carta di credito (visa,mastercard, ecc)]
   * @param  {[String]} last4 [ultime 4 cifre della carta di credito, uniche cifre visibili]
   * @return {[String]}       [stringa contenente i numeri della carta oscurati]
   */
  var getCardNumber = function (brand, last4) {
    var cardNumber = '';
    switch (brand.toLowerCase()) {
      case 'visa':
      case 'mastercard':
      case 'discover':
      case 'jqb':
        cardNumber = '**** **** **** ' + last4;
        break;
      case 'american express':
        cardNumber = '**** ****** * ' + last4;
        break;
      case 'diners club':
        cardNumber = '**** **** ** ' + last4;
        break;
    }
    return cardNumber;
  };

  /**
   * [intercetta la risposta del server Stripe alla richiesta di aggiunta della carta di credito]
   * @param  {[Integer]} status   [status della risposta]
   * @param  {[Object]} response [oggetto contenente tutti i dati ritornati da Stripe dopo il
   *   salvataggio della carta di credito]
   */
  this.stripeResponseHandler = function (status, response) {
    if (response.error) {
      modalTemplate.ui.messageEl.text(response.error.message)
        .addClass('koMessage')
        .removeClass('okMessage');
      modalTemplate.ui.actionButton.removeClass('disabled');
      modalTemplate.ui.cancelButton.removeClass('disabled');
    } else {
      modalTemplate.ui.messageEl.text('Saving...').addClass('okMessage').removeClass('koMessage');

      var token = response.id;
      var options = {
        stripe_token: token,
      };
      var setTokenRequest = Wethod.request('payment:set:token', options);
      $.when(setTokenRequest).done(function () {
        var getUserCardRequest = Wethod.request('payment:get:info');
        $.when(getUserCardRequest).done(function (getUserCardResponse) {
          if (_.isUndefined(getUserCardResponse)) {
            return;
          }

          // render nuovi dati carta
          var cardTemplate;
          var cardNumber = getCardNumber(getUserCardResponse.get('card').brand, getUserCardResponse.get('card').last4);
          var cardModel = new Wethod.SettingsApp.Payment.CreditCardModel();

          var month = (parseInt(getUserCardResponse.get('card').exp_month) > 9) ? parseInt(getUserCardResponse.get('card').exp_month) : '0' + parseInt(getUserCardResponse.get('card').exp_month);
          cardModel.set({
            cardNumber: cardNumber,
            exp_month: month,
            exp_year: getUserCardResponse.get('card').exp_year,
          });
          cardTemplate = new Account.CreditCardTemplateItemView({
            model: cardModel,
          });

          paymentStructureTemplate.ui.deleteCard.removeClass('hide');
          // Edit function is disabled so the button is hidden and there's no need to change the
          // label paymentStructureTemplate.ui.editCardLabel.text('EDIT');
          paymentStructureTemplate.ui.editCard.addClass('hide');

          paymentStructureTemplate.cardBox.show(cardTemplate);

          // sistemo la sezione promocode
          paymentStructureTemplate.ui.promocodeButton.removeClass('disabled');
          paymentStructureTemplate.ui.promocodeAlert.hide();
          paymentStructureTemplate.ui.promocode.attr({ disabled: false });

          modalTemplate.ui.messageEl.text('Saved!').addClass('okMessage').removeClass('koMessage');

          setTimeout(function () {
            modalTemplate.ui.actionButton.removeClass('disabled');
            modalTemplate.ui.cancelButton.removeClass('disabled');
            modalTemplate.destroy();
          }, 2000);
        });
      });
    }
  };

  /**
   * [cancella la carta di credito associata al customer in sessione]
   */
  this.stripeDeleteCard = function () {
    modalTemplate.ui.actionButton.addClass('disabled');
    modalTemplate.ui.cancelButton.addClass('disabled');
    modalTemplate.ui.messageEl.text('Deleting...').addClass('okMessage').removeClass('koMessage');

    var deleteCardRequest = Wethod.request('delete:payment:card');
    $.when(deleteCardRequest).done(function () {
      paymentStructureTemplate.ui.deleteCard.addClass('hide');
      paymentStructureTemplate.ui.editCard.removeClass('hide');
      // No need to change the label when 'edit' button is disabled
      // paymentStructureTemplate.ui.editCardLabel.text('+ CARD');

      var cardTemplate = new Account.NoCreditCardTemplateItemView();
      paymentStructureTemplate.cardBox.show(cardTemplate);

      // sistemo la sezione promocode
      paymentStructureTemplate.ui.promocodeButton.addClass('disabled');
      paymentStructureTemplate.ui.promocodeAlert.show();
      paymentStructureTemplate.ui.promocode.attr({ disabled: 'disabled' }).val('');
      paymentStructureTemplate.ui.promocodeFeedback.removeClass('payment-promocode-feedback-error')
        .text('');

      modalTemplate.ui.messageEl.text('Deleted').addClass('okMessage').removeClass('koMessage');
      modalTemplate.destroy();
    });
  };

  /**
   * [renderizza modal generico per le azioni di conferma dell'utente]
   * @param  {[Object]} data [contiene tutti i dati per creare il modello del modal]
   */
  this.showModal = function (data) {
    var modalModel = new Wethod.SettingsApp.Payment.ModalModel(data);

    modalTemplate = new Account.ModalItemView({
      model: modalModel,
    });
    paymentStructureTemplate.modal.show(modalTemplate);
  };

  /**
   * [renderizza modal associato all'aggiunta della carta di credito]
   */
  this.showAddCardModal = function () {
    modalTemplate = new Account.AddCardModalItemView();
    paymentStructureTemplate.modal.show(modalTemplate);
  };

  /**
   * [renderizza modal con i dati di fatturazione]
   */
  this.showBillingInfoModal = function () {
    var modalModel = new Wethod.SettingsApp.Payment.ModalModel({
      type: billingInfoModel.get('type'),
    });
    modalTemplate = new Account.BillingInfoModalItemView({
      model: modalModel,
    });
    paymentStructureTemplate.modal.show(modalTemplate);
  };

  /**
   * [ renderizza il form corretto a seconda della scelta dell'utente (private/company) ]
   * @param  {[Integer]}      type  [ valore della modalità associata a private = 0 /company = 1 ]
   * @param  {[Marionette Obj]}  modal [ obj marionette della view del modal ]
   */
  this.switchBillingType = function (type, modal) {
    var editModel;
    switch (type) {
      case 0: // private
        if (billingInfoModel.get('type') === 0) {
          editModel = billingInfoModel;
        } else {
          editModel = new Wethod.SettingsApp.Payment.BillingInfoModel();
        }
        break;
      case 1: // company
        if (billingInfoModel.get('type') === 1) {
          editModel = billingInfoModel;
        } else {
          editModel = new Wethod.SettingsApp.Payment.BillingInfoModel();
          editModel.set({ type: 1 });
        }
        break;
    }

    var editTemplate = new Account.BillingEditItemView({
      model: editModel,
      parentView: modal,
    });

    modal.options.userBillingInfo = editModel;
    modal.ui.editBillingInfoArea.html(editTemplate.render().$el);
  };

  /**
   * [ visualizza gli errori all'interno del modal di inserimento dei dati di fatturazione ]
   * @param  {[Array]} errors [array di oggetti che contengono l'errore la key permette di
   *   individuare il campo che genera l'errore mentre il valore è il messaggio da visualizzare]
   */
  var displayBillingInfoError = function (errors) {
    _.each(errors, function (error) {
      _.each(error, function (val, key) {
        modalTemplate.ui.messageEl.text(val).addClass('koMessage').removeClass('okMessage');

        $('input[name="' + key + '"]').addClass('billingError');
        $('input[name="' + key + '"]')
          .parent()
          .find('.mdl-textfield__label')
          .addClass('billingError');
      });
    });
  };

  /**
   * [ valida il modello da salvare, se non ci sono errori fa il persist dell'oggetto sul db e
   * renderizza i dati inseriti nella view principale ]
   * @param  {[Backbone Obj]} model [ modello Backbone che contiene i dati di fatturazione
   *   dell'utente ]
   */
  this.saveBillingInfo = function (model) {
    if (model.isValid()) {
      if (!_.isUndefined(billingInfoModel.get('id'))) {
        model.set({ id: billingInfoModel.get('id') });
      }

      switch (model.get('type')) {
        case 0:
          model.set({ business_name: '' });
          break;
        case 1:
          model.set({
            name: '',
            surname: '',
          });
          break;
      }
      var saveBillingInfoRequest = Wethod.request('payment:save:billingInfo', model);
      modalTemplate.ui.messageEl.text('Saving...').addClass('okMessage').removeClass('koMessage');
      $.when(saveBillingInfoRequest).done(function (saveBillingInfoResponse) {
        billingInfoModel = saveBillingInfoResponse;
        var recapTemplate = new Account.BillingInfoTemplateItemView({
          model: billingInfoModel,
        });

        modalTemplate.ui.messageEl.text('Saved!').addClass('okMessage').removeClass('koMessage');

        // structureTemplate.ui.deleteBillingInfo.removeClass('hide');
        paymentStructureTemplate.ui.editBillingInfoLabel.text('EDIT');
        paymentStructureTemplate.ui.editBillingInfo.removeClass('col_7').addClass('col_5');

        paymentStructureTemplate.billingBox.show(recapTemplate);
        // abilito il tasto per l'inserimento della carta di credito
        paymentStructureTemplate.ui.editCard.removeClass('disabled');

        modalTemplate.ui.messageEl.text('Saved!').addClass('okMessage').removeClass('koMessage');

        setTimeout(function () {
          modalTemplate.ui.actionButton.removeClass('disabled');
          modalTemplate.ui.cancelButton.removeClass('disabled');
          modalTemplate.destroy();
        }, 2000);
      });
    } else {
      modalTemplate.ui.actionButton.removeClass('disabled');
      modalTemplate.ui.cancelButton.removeClass('disabled');

      displayBillingInfoError(model.validationError);
    }
  };

  /**
   * [ riumuove i dati di fatturazione dal DB e aggiorna la view ]
   */
  this.deleteBillingInfo = function () {
    modalTemplate.ui.actionButton.addClass('disabled');
    modalTemplate.ui.cancelButton.addClass('disabled');
    modalTemplate.ui.messageEl.text('Deleting...').addClass('okMessage').removeClass('koMessage');

    var deleteCardRequest = Wethod.request('payment:delete:billingInfo', billingInfoModel);
    $.when(deleteCardRequest).done(function () {
      structureTemplate.ui.deleteBillingInfo.addClass('hide');
      structureTemplate.ui.editBillingInfoLabel.text('+ BILLING INFO');

      billingInfoModel = new Account.BillingInfoModel();

      var noBillingInfoTemplate = new Account.NoBillingInfoTemplateItemView();
      structureTemplate.billingBox.show(noBillingInfoTemplate);

      modalTemplate.ui.messageEl.text('Deleted').addClass('okMessage').removeClass('koMessage');
      modalTemplate.destroy();
    });
  };

  // IMPORT
  this.submitImport = function (formData) {
    // todo this should be in model.js

    $.ajax({
      method: 'POST',
      url: APIURL + '/import/upload',
      crossDomain: true,
      xhrFields: {
        withCredentials: true,
      },
      processData: false,
      contentType: false,
      data: formData,
    }).done(function (response) {
      if (response.code != '200' && response.code != 201) {
        contentLayout.showError(response.message);
      } else {
        contentLayout.hideError();
        contentLayout.notify('File imported');
      }
    });
  };

  this.showDeleteModal = function () {
    var modalDeteAccountTemplate = new Account.AccountModalDeleteItemView();
    structureTemplate.modal.show(modalDeteAccountTemplate);
  };
  this.DeleteCompany = function (password) {
    var options = {
      password: password,
    };
    var deleteAcccountRequest = Wethod.request('delete:company:account', options);
    $.when(deleteAcccountRequest).done(function (deleteAcccountResponse) {
      if (deleteAcccountResponse.code === 200) {
        window.location.replace(URL + LOGINPAGE);
      }
    });
  };

  this.activePromocode = function (promocode, viewObj) {
    viewObj.ui.promocodeFeedback.removeClass('payment-promocode-feedback-error').text('Loading...');

    var options = { promocode: promocode };
    var checkPromocodeRequest = Wethod.request('payment:check:promocode', options);
    $.when(checkPromocodeRequest).done(function (checkPromocodeResponse) {
      switch (checkPromocodeResponse.code) {
        case 400:
          viewObj.ui.promocodeFeedback.addClass('payment-promocode-feedback-error')
            .text(checkPromocodeResponse.message);
          viewObj.ui.promocodeButton.removeClass('disabled');
          break;
        case 200:
          var promocodeAdded = 'Promocode Added';
          viewObj.ui.promocodeFeedback.removeClass('payment-promocode-feedback-error')
            .text(promocodeAdded);

          viewObj.ui.promocodeFeedback.removeClass('payment-promocode-feedback-error').text('');

          paymentStructureTemplate.ui.activePromocode.text(checkPromocodeResponse.data.coupon);

          paymentStructureTemplate.ui.activePromocodeUntil
            .text(moment(checkPromocodeResponse.data.end)
              .format('DD/MM/YYYY'));

          if (inProduction) {
            ga('send', {
              hitType: 'event',
              eventCategory: 'promocode',
              eventAction: 'promocode attivato',
              eventLabel: promocode,
            });
          }

          paymentStructureTemplate.ui.promocodeAdd.hide();
          paymentStructureTemplate.ui.promocodeInfo.show();

          break;
      }

      viewObj.ui.promocodeButton.removeClass('disabled');
    });
  };

  Account.Controller = {
    showAccount: function (options) {
      var canEditCard = false;
      structureTemplate = new Account.StructureLayoutView();

      var getAccountInfoRequest;
      var getBillingInfoRequest;
      var userInfoRequest;

      if (Wethod.userInfo.get('is_owner')) {
        getAccountInfoRequest = Wethod.request('payment:get:info');
        getBillingInfoRequest = Wethod.request('payment:get:billingInfo');
      }

      userInfoRequest = Wethod.request('get:settings:userInfo');
      var enabledIntegrationsRequest = Wethod.request('integration:all');
      // Retrieve url for google oauth2 form showing
      var calendarUrlRequest = Wethod.request('integration:enable:google',
        {
          scope: 'calendar',
          referer: encodeURIComponent(document.location.href),
        });

      $.when(userInfoRequest, getAccountInfoRequest, getBillingInfoRequest,
        enabledIntegrationsRequest, calendarUrlRequest)
        .done(function (userInfoResponse, getAccountInfoResponse, getBillingInfoResponse,
          enabledIntegrationResponse, calendarUrlResponse) {
          var bodyTemplate = new Account.AccountBodyItemView({
            model: userInfoResponse,
          });

          var sectionHeaderModel = new Header.SectionHeaderModel({
            current_section: 'Account',
            helper_url: 'settings/index/#account',
          });

          var sectionHeaderView = new Header.SectionHeaderView({ model: sectionHeaderModel });

          structureTemplate.render();
          structureTemplate.head.show(sectionHeaderView);
          structureTemplate.body.show(bodyTemplate);

          var integrationsModel = new Backbone.Model({
            enableUrl: decodeURI(calendarUrlResponse.data.url),
          });
          // INTEGRATIONS
          integrationsStructureTemplate = new Account.IntegrationsStructureLayoutView(
            {
              enabled: enabledIntegrationResponse,
              model: integrationsModel,
            }
          );
          integrationsStructureTemplate.render();

          if (Wethod.featureService.isEnabled(FeatureService.PUBLIC_API)) {
            var reactLayoutContainer = document.querySelector('[data-region="reactSection"]');
            var reducer = new Reducer({});
            var store = new Store(reducer.reduxReducer);
            // Use this layout to add new React components to this sections
            var reactLayoutElement = React.createElement(ReactLayout, { store: store });
            ReactDOM.render(reactLayoutElement, reactLayoutContainer);
          }

          // PAYMENT
          if (!_.isUndefined(getAccountInfoResponse) && !_.isUndefined(getBillingInfoResponse)) {
            // render della struttura
            paymentStructureTemplate = new Account.PaymentStructureLayoutView();
            paymentStructureTemplate.render();
            // CREDIT CARD
            var cardTemplate;
            // se c'è una carta già associata al customer la visualizza
            if (
              !_.isUndefined(getAccountInfoResponse.get('card')) && !_.isUndefined(getAccountInfoResponse.get('card').last4)
            ) {
              var cardModel = new Wethod.SettingsApp.Payment.CreditCardModel();

              var cardNumber = getCardNumber(getAccountInfoResponse.get('card').brand, getAccountInfoResponse.get('card').last4);

              var month = (parseInt(getAccountInfoResponse.get('card').exp_month) > 9) ? parseInt(getAccountInfoResponse.get('card').exp_month) : '0' + parseInt(getAccountInfoResponse.get('card').exp_month);

              cardModel.set({
                cardNumber: cardNumber,
                exp_month: month,
                exp_year: getAccountInfoResponse.get('card').exp_year,
              });
              cardTemplate = new Account.CreditCardTemplateItemView({
                model: cardModel,
              });

              paymentStructureTemplate.ui.deleteCard.removeClass('hide');
              // Edit function is disabled so the button is hidden and there's no need to change
              // the label paymentStructureTemplate.ui.editCardLabel.text('EDIT');
              paymentStructureTemplate.ui.editCard.addClass('hide');
            } else {
              // se non c'è la carta di credito tolgo la possibilità di aggiungere un promocode
              paymentStructureTemplate.ui.promocodeButton.addClass('disabled');
              // mostro l'alert del promocode
              paymentStructureTemplate.ui.promocodeAlert.show();
              paymentStructureTemplate.ui.promocode.attr({ disabled: 'disabled' });

              cardTemplate = new Account.NoCreditCardTemplateItemView();
            }

            // BILLING INFO
            billingInfoModel = getBillingInfoResponse;

            var billingInfoTemplate;
            // in base ai dati ritornati dal server decido che view visualizzare
            if (_.isUndefined(getBillingInfoResponse.get('id'))) {
              billingInfoTemplate = new Account.NoBillingInfoTemplateItemView();
              paymentStructureTemplate.ui.editBillingInfo.removeClass('col_5').addClass('col_7');
            } else {
              canEditCard = true;
              billingInfoTemplate = new Account.BillingInfoTemplateItemView({
                model: billingInfoModel,
              });

              // abilito il pulsante per rimuovere i dati di fatturazione
              // structureTemplate.ui.deleteBillingInfo.removeClass('hide');
              paymentStructureTemplate.ui.editBillingInfoLabel.text('EDIT');
            }

            if (options.showPayment) {
              paymentStructureTemplate.billingBox.show(billingInfoTemplate, options);
            } else {
              paymentStructureTemplate.billingBox.show(billingInfoTemplate);
            }

            // promocode
            if (!_.isEmpty(getAccountInfoResponse.get('discount'))) {
              paymentStructureTemplate.ui.activePromocode.text(getAccountInfoResponse.get('discount').coupon);

              paymentStructureTemplate.ui.activePromocodeUntil.text(moment(getAccountInfoResponse.get('discount').end)
                .format('DD/MM/YYYY'));

              paymentStructureTemplate.ui.promocodeAdd.hide();
              paymentStructureTemplate.ui.promocodeInfo.show();
            }

            paymentStructureTemplate.cardBox.show(cardTemplate);

            if (canEditCard) {
              paymentStructureTemplate.ui.editCard.removeClass('disabled');
            }
          } else {
            structureTemplate.ui.accountHeader.hide();
            structureTemplate.ui.paymentSection.hide();
          }

          // DELETE
          if (Wethod.userInfo.get('is_owner')) {
            var deteAccountTemplate = new Account.AccountDeleteBodyItemView();
            structureTemplate.delete.show(deteAccountTemplate);
          } else {
            structureTemplate.ui.deleteSection.hide();
          }
        });
    },
  };
}, Wethod.module('HeaderApp.Header'));
