const React = require('react');
const PropTypes = require('prop-types');
const TableColumnList = require('./models/TableColumnList');
const TableRow = require('./TableRow.react');

/**
 * A modular React table.
 */
class Table2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scrollBarWidth: 0,
      searchHeight: 0,
      headHeight: 0,
      footerHeight: 0,
    };
  }

  /**
   * Calculates and returns body height by using tableHeight as total and subtracting all the
   * elements other from body.
   * @param tableHeight
   * @return {*}
   */
  getRelativeBodyHeight(tableHeight) {
    if (tableHeight) {
      return tableHeight - this.state.searchHeight - this.state.headHeight
        - this.state.footerHeight;
    }
    return null;
  }

  getColumnsAmount() {
    const header = this.props.children[0];
    if (!header) {
      return 0;
    }
    if (header?.props?.children?.type === TableRow) {
      return React.Children.toArray(header.props.children.props.children).length;
    }
    return React.Children.toArray(header.props.children).length;
  }

  getContent() {
    const expanded = typeof this.props.expanded === 'boolean' ? this.props.expanded : true;

    const minBodyHeight = this.getRelativeBodyHeight(this.props.minHeight);
    const maxBodyHeight = this.getRelativeBodyHeight(this.props.maxHeight);

    return React.Children.map(this.props.children,
      (children) => React.cloneElement(children, {
        columns: this.getColumnsAmount(),
        scrollBarWidth: this.state.scrollBarWidth,
        updateScrollBarWidth: this.updateScrollBarWidth.bind(this),
        updateHeight: this.updateHeight.bind(this),
        visibleColumns: this.props.visibleColumns.withElasticWidth(),
        minBodyHeight,
        maxBodyHeight,
        bodyHeight: expanded ? maxBodyHeight : minBodyHeight,
      }));
  }

  getSearch() {
    const { search } = this.props;

    if (search) {
      return {
        ...search,
        props: {
          ...search.props,
          updateHeight: this.updateHeight.bind(this),
        },
      };
    }
    return null;
  }

  getFooter() {
    const { footer } = this.props;

    if (footer) {
      return {
        ...footer,
        props: {
          ...footer.props,
          updateHeight: this.updateHeight.bind(this),
        },
      };
    }
    return null;
  }

  /**
   * Updates name's height.
   *
   * @param name head|search|footer
   * @param value new height's value
   */
  updateHeight(name, value) {
    this.setState({ [`${name}Height`]: value });
  }

  updateScrollBarWidth(width) {
    this.setState({ scrollBarWidth: width });
  }

  render() {
    return (
      <div className="wethod-table">
        {this.getSearch()}
        <table className="wethod-table__content">
          {this.getContent()}
        </table>
        {this.getFooter()}
      </div>
    );
  }
}

Table2.defaultProps = {
  visibleColumns: new TableColumnList(),
  maxHeight: null,
  minHeight: null,
  expanded: true,
  search: null,
  footer: null,
};

Table2.propTypes = {
  children: PropTypes.node.isRequired,
  /**
   * List of columns to show. An empty list means that all columns are visible.
   */
  visibleColumns: PropTypes.instanceOf(TableColumnList),
  /**
   * Height of the table when expanded.
   */
  maxHeight: PropTypes.number,
  /**
   * Height of the table when not expanded.
   */
  minHeight: PropTypes.number,
  expanded: PropTypes.bool,
  /**
   * The search component to use.
   *
   * @see TableSearch
   */
  search: PropTypes.node,
  /**
   * The footer component to use.
   *
   * @see TableFooter
   */
  footer: PropTypes.node,
};

module.exports = Table2;
