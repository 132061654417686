const React = require('react');

/**
 * A filler block representing hours that exceed those defined by the employee work hour capacity.
 *
 * @param amount The amount of hours to be filled.
 */
const CapacityBlock = ({ hours }) => {
  const className = 'planning-calendar__hour planning-calendar__hour--block planning-calendar__hour--capacity';

  const style = {
    height: `${hours * (54 / 8)}px`,
  };

  return (<div className={className} style={style} />);
};

module.exports = CapacityBlock;
