/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,consistent-return */
const React = require('react');
const Track = require('../containers/CalendarRowTrack');
const HolidayManager = require('../containers/HolidayManager');

module.exports = class CalendarRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editorMode: null, // null | add | remove
    };
    this.pendingChanges = []; // waiting to be saved
  }

  /**
   * Add changes to this.pendingChanges or modify it if already present.
   * A change is already present if it has same date and project of one in this.pendingChanges.
   * @param changes
   */
  updateChanges(changes) {
    for (let i = 0; i < changes.length; i++) {
      const toAdd = changes[i];
      let updated = false;
      for (let j = 0; j < this.pendingChanges.length && !updated; j++) {
        const current = this.pendingChanges[j];
        const sameProject = toAdd.project_id === current.project_id;
        const sameDate = toAdd.day === current.day;
        if (sameDate && sameProject) {
          this.pendingChanges[j].amount = changes[i].amount;
          updated = true;
        }
      }
      if (!updated) {
        this.pendingChanges.push(toAdd);
      }
    }
  }

  updateEditorMode(mode) {
    this.setState({ editorMode: mode });
  }

  getPlansByDate() {
    const { plans } = this.props.person;
    const plansByDate = {};

    for (let i = 0; i < plans.length; i++) {
      const plan = plans[i];
      if (!plansByDate[plan.day]) {
        plansByDate[plan.day] = [];
      }
      plansByDate[plan.day].push(plan);
    }

    return plansByDate;
  }

  getTracksWithPlan() {
    const plansByDate = this.getPlansByDate();
    return this.props.tracks.map((track) => ({
      date: track[0],
      days: track.map((day) => {
        const plans = plansByDate[day];
        return {
          date: day,
          plans: plans || [],
        };
      }),
    }));
  }

  /**
   * Get how many hours can be planned for this person's level in the selected project.
   * @see https://docs.google.com/document/d/1IbWJtAo3dIospfBEcARTbw9Bm5BVFnB2KZqR2YRZ2ME/edit#bookmark=id.h88gcujolkf6
   * @returns {number}
   */
  getPlannableHours() {
    const { selectedProject, person, fte } = this.props;
    const fteHours = fte / 60;

    if (selectedProject) {
      if (selectedProject.over_limit || selectedProject.type.unlimited_planning) {
        return Number.POSITIVE_INFINITY;
      }
      const level = selectedProject.levels.filter((level) => level.id === person.level.id)[0];
      const contingency = (selectedProject.levels.reduce((sum, level) => sum + level.total_days, 0)
        * selectedProject.budget.contingency) / 100;
      const usedContingency = selectedProject.levels.reduce((sum, level) => {
        const availableDays = level.total_days - level.planned_days + level.revision_days;
        const levelDebt = Math.min(availableDays, 0);

        return sum + Math.abs(levelDebt);
      }, 0);
      const availableContingency = Math.max(contingency - usedContingency, 0);
      const availableDays = level
        ? Math.max(0, level.total_days - level.planned_days + level.revision_days) : 0;

      return (availableDays + availableContingency) * fteHours;
    }
  }

  getTracksFragment() {
    const tracksWithPlan = this.getTracksWithPlan();
    return tracksWithPlan.map((track, index) => {
      const prevTrackDays = index ? tracksWithPlan[index - 1].days : [];
      return (
        <HolidayManager key={track.date}>
          <Track
            days={track.days}
            prevTrackDays={prevTrackDays}
            person={this.props.person}
            showEditor={this.props.selectedProject}
            updateChanges={this.updateChanges.bind(this)}
            editorMode={this.state.editorMode}
            updateHours={this.updateHours.bind(this)}
            updateEditorMode={this.updateEditorMode.bind(this)} />
        </HolidayManager>
      );
    });
  }

  onMouseLeave() {
    this.setState({ editorMode: null });
  }

  onMouseUp(e) {
    const highlighting = this.state.editorMode !== null;
    if (highlighting) {
      e.stopPropagation();
      if (this.pendingChanges.length) {
        this.updateHours(this.pendingChanges, this.state.editorMode);
      }
      this.updateEditorMode(null);
      this.pendingChanges = [];
    }
  }

  /**
   * Return the "real" changes, that are changes with a real amount modification.
   * If the amount goes from 0 to 0, this is not a real change.
   * @param changes
   * @returns {*[]}
   */
  getMeaningfulChanges(changes) {
    const meaningfulChanges = [];
    const { plans } = this.props.person;
    for (let i = 0; i < changes.length; i++) {
      const change = changes[i];
      // There are no plan equal to this change
      const isUnique = plans.filter((plan) => plan.day === change.day
        && plan.project_id === change.project_id
        && plan.amount === change.amount).length === 0;
      // There is no plan yet for the change's project and day
      const emptyDay = plans.filter((plan) => plan.day === change.day
        && plan.project_id === change.project_id).length === 0;
      const isNoOp = emptyDay && change.amount === 0;
      if (isUnique && !isNoOp) {
        meaningfulChanges.push(change);
      }
    }
    return meaningfulChanges;
  }

  updateHours(changes) {
    const meaningfulChanges = this.getMeaningfulChanges(changes);
    const changesWithProject = meaningfulChanges.map((change) => ({
      ...change,
      project_data: this.props.plannedProjects[change.project_id],
    }));

    if (this.props.validateChanges(changesWithProject, this.getPlannableHours())) {
      this.props.editPlan(changesWithProject);
    }
  }

  render() {
    return (
      <div
        className="planning-calendar__row"
        onMouseLeave={this.onMouseLeave.bind(this)}
        onMouseUp={this.onMouseUp.bind(this)}>
        {this.getTracksFragment()}
      </div>
    );
  }
};
