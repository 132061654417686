module.exports.GET_TEAM_REQUEST = 'GET_TEAM_REQUEST';
module.exports.GET_TEAM_SUCCESS = 'GET_TEAM_SUCCESS';

module.exports.GET_LEVELS_REQUEST = 'GET_LEVELS_REQUEST';
module.exports.GET_LEVELS_SUCCESS = 'GET_LEVELS_SUCCESS';

module.exports.GET_ROLES_REQUEST = 'GET_ROLES_REQUEST';
module.exports.GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';

module.exports.SEND_INVITE_REQUEST = 'SEND_INVITE_REQUEST';
module.exports.SEND_INVITE_SUCCESS = 'SEND_INVITE_SUCCESS';

module.exports.GET_JOB_TITLES_REQUEST = 'GET_JOB_TITLES_REQUEST';
module.exports.GET_JOB_TITLES_SUCCESS = 'GET_JOB_TITLES_SUCCESS';

module.exports.GET_PRICELIST_REQUEST = 'GET_PRICELIST_REQUEST';
module.exports.GET_PRICELIST_SUCCESS = 'GET_PRICELIST_SUCCESS';

module.exports.SET_FILTERS = 'SET_FILTERS';

module.exports.SET_TAGS_CATEGORIES = 'SET_TAGS_CATEGORIES';
