const React = require('react');
const Modal = require('../../../../../../../common/react/Modal/Modal.react');
const ModalContent = require('../../../../../../../common/react/Modal/ModalContent.react');
const ModalBody = require('../../../../../../../common/react/Modal/ModalBody.react');
const ModalBodyBlock = require('../../../../../../../common/react/Modal/ModalBodyBlock.react');
const ModalFooter = require('../../../../../../../common/react/Modal/ModalFooter.react');
const ModalFooterAction = require('../../../../../../../common/react/Modal/ModalFooterAction.react');
const DateService = require('../../../../../../../services/DateService');

/**
 * Modal to ask confirmation when user tries to exceed work hours capacity.
 * @param {function} onCancelClick
 * @param {function} editPlan
 * @param {function} confirmCapacityPlanning
 * @param changes
 * @param plans
 * @param person
 * @return {JSX.Element}
 * @constructor
 */
const ConfirmCapacityPlanModal = ({
  onCancelClick,
  editPlan,
  confirmCapacityPlanning,
  changes,
  plans,
  person,
}) => {
  function handleContinueClick() {
    editPlan(changes);
    onCancelClick();
    confirmCapacityPlanning();
  }

  const getChangesWithoutCapacityHours = () => changes.map((change) => {
    const otherProjectsPlannedHours = plans
      .filter((plan) => plan.day === change.day && plan.project_id !== change.project_id)
      .reduce((acc, plan) => acc + plan.amount, 0);

    const sameProjectPlannedHours = plans
      .filter((plan) => plan.day === change.day && plan.project_id === change.project_id)
      .reduce((acc, plan) => acc + plan.amount, 0);

    const weekDay = DateService.getWeekDayName(change.day);
    const workHourCapacity = Math.ceil(person.work_hour_capacity[`${weekDay}_minutes`] / 60);

    const plannableHours = workHourCapacity - otherProjectsPlannedHours;
    const targetHours = Math.min(plannableHours, change.amount);
    const amount = Math.max(targetHours, sameProjectPlannedHours);

    return { ...change, amount, original_amount: sameProjectPlannedHours };
  })
    // Remove plans with 0 hours
    .filter((change) => change.amount > 0)
    // Remove unchanged plans
    .filter((change) => change.amount !== change.original_amount);

  function handleExcludeClick() {
    const changesWithoutCapacityHours = getChangesWithoutCapacityHours();
    if (changesWithoutCapacityHours.length) {
      editPlan(changesWithoutCapacityHours);
    }
    onCancelClick();
  }

  return (
    <Modal onClose={onCancelClick} title="Planning Over Time">
      <ModalContent>
        <ModalBody>
          <ModalBodyBlock>
            You are going to plan over time.
          </ModalBodyBlock>
          <ModalBodyBlock>
            Would you like to continue or do you want to exclude changes done on not available time?
          </ModalBodyBlock>
        </ModalBody>
        <ModalFooter>
          <ModalFooterAction onClick={handleExcludeClick}>
            Exclude them
          </ModalFooterAction>
          <ModalFooterAction onClick={handleContinueClick}>
            Continue
          </ModalFooterAction>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

module.exports = ConfirmCapacityPlanModal;
