const _ = require('underscore');
const moment = require('moment');
const constants = require('./constants');
const TeamModel = require('../../../../../models/Team');
const LevelModel = require('../../../../../models/Level');
const RoleModel = require('../../../../../models/Role');
const JobTitleModel = require('../../../../../models/JobTitle');
const PricelistModel = require('../../../../../models/Pricelist');
const EmployeeTagsModel = require('../../../../../models/EmployeeTags');

module.exports.getLevels = () => (dispatch) => {
  dispatch({ type: constants.GET_LEVELS_REQUEST });
  return LevelModel.list()
    .done((levels) => dispatch({
      type: constants.GET_LEVELS_SUCCESS,
      levels,
    }));
};

module.exports.getRoles = () => (dispatch) => {
  dispatch({ type: constants.GET_ROLES_REQUEST });
  return RoleModel.list()
    .done((roles) => dispatch({
      type: constants.GET_ROLES_SUCCESS,
      roles,
    }));
};

module.exports.getJobTitles = (filters) => (dispatch) => {
  dispatch({ type: constants.GET_JOB_TITLES_REQUEST });
  return JobTitleModel.list(filters)
    .done((jobTitles) => dispatch({
      type: constants.GET_JOB_TITLES_SUCCESS,
      jobTitles,
    }));
};

module.exports.getPricelists = () => (dispatch) => {
  dispatch({ type: constants.GET_PRICELIST_REQUEST });
  return PricelistModel.list()
    .done((pricelists) => {
      pricelists.forEach((pricelist) => {
        pricelist.isExpired = moment().isAfter(moment(pricelist.available_to)); // eslint-disable-line no-param-reassign
      });
      dispatch({
        type: constants.GET_PRICELIST_SUCCESS,
        pricelists,
      });
    });
};

module.exports.inviteUser = ({ email, role, level }) => (dispatch, getState) => {
  dispatch({ type: constants.SEND_INVITE_REQUEST });
  const { roles, levels } = getState();
  return TeamModel.createInvitations([{
    employee_email: email,
    employee_role: roles.find((r) => r.name === role).id,
    employee_level: levels.find((l) => l.text === level).id,
    url: window.location.origin + window.location.pathname,
  }])
    .done(() => dispatch({
      type: constants.SEND_INVITE_SUCCESS,
    }));
};

module.exports.deleteInvitation = (...args) => () => TeamModel.deleteInvitation(...args);

module.exports.savePriceListLevel = (...args) => () => TeamModel.savePriceListLevel(...args);

module.exports.saveRole = (...args) => () => TeamModel.saveRole(...args);

module.exports.saveJobTitle = (...args) => () => TeamModel.saveJobTitle(...args);

module.exports.saveTag = (...args) => () => TeamModel.saveTag(...args);

module.exports.getTagsAutocomplete = (...args) => () => TeamModel.getTagsAutocomplete(...args);

module.exports.showOnPlanning = (...args) => () => TeamModel.showOnPlanning(...args);

module.exports.hideFromPlanning = (...args) => () => TeamModel.hideFromPlanning(...args);

module.exports.setTimesheetNeeded = (...args) => () => TeamModel.setTimesheetNeeded(...args);

module.exports.archiveEmployee = (...args) => () => TeamModel.archiveEmployee(...args);

module.exports.unarchiveEmployee = (...args) => () => TeamModel.unarchiveEmployee(...args);

module.exports.getChangeLog = (...args) => () => TeamModel.getChangeLog(...args);

module.exports.updateAllocationManager = (...args) => () => (
  TeamModel.updateAllocationManager(...args)
);

module.exports.saveUserCode = (...args) => () => TeamModel.saveUserCode(...args);

module.exports.updateUserCode = (...args) => () => TeamModel.updateUserCode(...args);

module.exports.deleteUserCode = (...args) => () => TeamModel.deleteUserCode(...args);

module.exports.importMetadata = (...args) => () => TeamModel.importMetadata(...args);

module.exports.exportMetadata = (...args) => () => TeamModel.exportMetadata(...args);

module.exports.getTagsCategories = () => (dispatch) => (
  EmployeeTagsModel.get()
    .done((tagsCategories) => dispatch({
      type: constants.SET_TAGS_CATEGORIES,
      tagsCategories,
    }))
);

module.exports.getItems = () => (dispatch, getState) => {
  const { typeFilter, keyword } = getState();

  dispatch({
    type: constants.GET_TEAM_REQUEST,
    typeFilter,
    keyword,
  });

  let getListRequest;

  if (typeFilter === 1) {
    getListRequest = TeamModel.getInvitations();
  } else {
    getListRequest = TeamModel.getAll();
  }

  return getListRequest.done((users) => {
    let team = users;

    if (typeFilter !== 1) {
      team = team.filter((user) => (
        user.employee.type === typeFilter
      ));
    }

    if (keyword) {
      if (typeFilter === 1) {
        team = team.filter((user) => (
          Object.values(user).some((value) => (
            typeof value === 'string' && value.toLowerCase().includes(keyword.toLowerCase())
          ))
        ));
      } else {
        team = team.filter((user) => (
          _.values(_.flatten(_.map(user, _.values))).some((value) => (
            typeof value === 'string' && value.toLowerCase().includes(keyword.toLowerCase())
          ))
        ));
      }
    }

    team.forEach((user) => {
      if (user.price_list?.available_to?.date) {
        user.price_list.isExpired = moment().isAfter(moment(user.price_list.available_to.date)); // eslint-disable-line no-param-reassign
      }
    });

    dispatch({
      type: constants.GET_TEAM_SUCCESS,
      allUsers: users,
      team,
    });
  });
};

module.exports.setFilters = (filters) => (dispatch) => {
  dispatch({
    type: constants.SET_FILTERS,
    filters,
  });
};
