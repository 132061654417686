const { connect } = require('react-redux');
const TabSwitcher = require('../../../../../../common/react/TabSwitcher.react');

const items = [
  {
    key: 0,
    name: 'Active Users',
  },
  {
    key: 1,
    name: 'Pending',
  },
  {
    key: 2,
    name: 'Archived',
  },
];

module.exports = connect((state) => ({
  items,
  current: state.typeFilter,
}), null)(TabSwitcher);
