const { connect } = require('react-redux');
const Component = require('../components/CalendarRow.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  selectedProject: state.selected_project,
  plannedProjects: state.planned_projects,
  fte: state.fte,
});

const mapDispatchToProps = (dispatch) => ({
  editPlan: (changes) => dispatch(actions.editPlan(changes)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
