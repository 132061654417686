'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var TeamStore = require('../../../apps/core/modules/settings/team/store');
var TeamReducer = require('../../../apps/core/modules/settings/team/reducer');
var TeamComponent = require('../../../apps/core/modules/settings/team/index');

/* eslint-disable */

Wethod.module('SettingsApp.Team', function (Team, Wethod, Backbone, Marionette, $, _, Header) {
  Team.Controller = {

    showTeam: function (options) {
      var authorizationRequest = Wethod.request('get:settings:authorization');
      $.when(authorizationRequest).done(function (authorizationResponse) {
        if (_.isUndefined(authorizationResponse)) {
          return;
        }

        Wethod.SettingsApp.authorization = authorizationResponse;

        if (Wethod.SettingsApp.getPermission('team')) {
          var layout = new Team.StructureLayoutView();
          layout.render();

          var sentence = Wethod.Utility
            .randomSentence(Wethod.sentencesDays, Wethod.sentencesSongs);
          var loadingView = new Wethod.View.Loading.BigView({ sentence: sentence });
          layout.expensesBody.show(loadingView);

          var reducerWrapper = new TeamReducer({
          });

          var store = TeamStore(reducerWrapper.reduxReducer);
          var element = React.createElement(TeamComponent, { store: store });
          var container = document.getElementById('root');
          if (container !== null) ReactDOM.render(element, container);

          dispatcher.trigger('tour:start', options.idTour);
        } else {
          var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
          Wethod.regions.body.show(permissionDeniedView);
          return;
        }
      });
    },

  };
}, Wethod.module('HeaderApp.Header'));
