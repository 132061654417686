const React = require('react');
const Header = require('../SubsectionHeader.react');
const ShowIf = require('../../../../../../../common/react/ShowIf/ShowIf.react');
const LoadingView = require('../job-order-template/LoadingView.react');
const EmployeeTagsTableHeader = require('./EmployeeTagsTableHeader.react');
const EmployeeTagsTableBody = require('./EmployeeTagsTableBody.react');
const Button = require('../../../../../../../common/react/Button/RoundedButton.react');

class EmployeeTagsTableReact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    this.props.getEmployeeTags();
  }

  render() {
    if (this.props.isVisible) {
      return (
        <div className="bottomSpace">
          <Header subtitle="Team Metadata"
            id="employee-tags-template"
            description="Set the category of metadata and their values for your team members." />
          <ShowIf condition={this.props.isWaiting}
            showElse={(
              <div id="employee-tags">
                <Button onClick={this.props.addNewCategory}
                  name="add-category-button"
                  className="company-settings__button company-settings__button--blue company-settings-margin-bottom-xl topMargin">
                  + add metadata
                </Button>
                <EmployeeTagsTableHeader />
                <EmployeeTagsTableBody
                  employeeTags={this.props.employeeTags}
                  onBlurCategory={this.props.onBlurCategory}
                  onDeleteCategory={this.props.onDeleteCategory}
                  isDeleting={this.props.isDeleting}
                  onDeleteCategoryRequest={this.props.onDeleteCategoryRequest}
                  onCreateCategory={this.props.onCreateCategory}
                  onCreateTag={this.props.onCreateTag}
                  onDeleteTag={this.props.onDeleteTag}
                  onSaveTag={this.props.onSaveTag}
                  setDisabledCategory={this.props.setDisabledCategory} />
              </div>
            )}>
            <LoadingView />
          </ShowIf>
        </div>
      );
    }
    return null;
  }
}

module.exports = EmployeeTagsTableReact;
