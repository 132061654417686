const React = require('react');
const moment = require('moment');
const FeatureService = require('../services/FeatureService');

/**
 * Component that injects in child utility methods to deal with employee capacity and company holidays.
 *
 * @param children
 * TODO [FTE_CONTRACT] Remove day without "_minutes" the fte is released for everyone
 * @param {{monday: number, tuesday: number, wednesday: number, thursday: number, friday: number, saturday: number, sunday: number, monday_minutes: number, tuesday_minutes: number, wednesday_minutes: number, thursday_minutes: number, friday_minutes: number, saturday_minutes: number, sunday_minutes: number}} employeeCapacity
 * @param [{date:string}] holidays List of holiday for the week
 * @param rest
 * @return {React.FunctionComponentElement<unknown>}
 * @constructor
 */
const CapacityManager = ({
  children, employeeCapacity, holidays,
  includeWeekend = false, ...rest
}) => {
  const formatDate = (date) => moment(date).format('YYYY-MM-DD');

  const minutesToHours = (minutes) => Math.round((minutes / 60) * 100) / 100;

  /**
   * Format capacity to be more usable.
   * @return {{thu: number, tue: number, wed: number, sat: number, fri: number, mon: number, sun: number}}
   */
  const getFormattedCapacity = () => {
    const holidayDays = holidays.map((holiday) => moment(holiday.date).format('ddd').toLowerCase());

    if (FeatureService.isFteEnabled()) {
      const formatted = {
        mon: minutesToHours(employeeCapacity.monday_minutes),
        tue: minutesToHours(employeeCapacity.tuesday_minutes),
        wed: minutesToHours(employeeCapacity.wednesday_minutes),
        thu: minutesToHours(employeeCapacity.thursday_minutes),
        fri: minutesToHours(employeeCapacity.friday_minutes),
        sat: minutesToHours(employeeCapacity.saturday_minutes),
        sun: minutesToHours(employeeCapacity.sunday_minutes),
      };
      if (!includeWeekend) {
        formatted.sat = 0;
        formatted.sun = 0;
      }
      holidayDays.forEach((day) => formatted[day] = 0);
      return formatted;
    }

    // TODO [FTE_CONTRACT] Remove this when the fte feature flag is enabled for everyone
    const formatted = {
      mon: employeeCapacity.monday,
      tue: employeeCapacity.tuesday,
      wed: employeeCapacity.wednesday,
      thu: employeeCapacity.thursday,
      fri: employeeCapacity.friday,
      sat: employeeCapacity.saturday,
      sun: employeeCapacity.sunday,
    };
    if (!includeWeekend) {
      formatted.sat = 0;
      formatted.sun = 0;
    }
    holidayDays.forEach((day) => formatted[day] = 0);

    return formatted;
  };

  const capacity = getFormattedCapacity();

  /**
   * Return true if given date is a company holiday for the employee.
   * @param {string} date
   * @return {boolean}
   */
  const isHoliday = (date) => holidays
    .map((holiday) => formatDate(holiday.date))
    .includes(formatDate(date));
  /**
   * Return employee capacity for the given date.
   * Capacity for a holiday is 0.
   * @param {string} date
   * @return {number}
   */
  const getDayCapacity = (date) => {
    if (isHoliday(date)) {
      return 0;
    }
    const dayIndex = moment(date).format('ddd').toLowerCase();
    return capacity[dayIndex];
  };

  /**
   * Return weekly sum of user capacity.
   * Capacity for holiday is 0,
   * @return {number}
   */
  const getWeekCapacity = () => capacity.mon + capacity.tue + capacity.wed + capacity.thu
      + capacity.fri + capacity.sat + capacity.sun;

  return React.cloneElement(children, {
    ...rest,
    getDayCapacity,
    isHoliday,
    getWeekCapacity,
  });
};

module.exports = CapacityManager;
