const React = require('react');
const { connect } = require('react-redux');
const actions = require('../actions');
const TableSearch = require('../../../../../../common/react/Table2/TableSearch.react');
const ExportModal = require('./modals/ExportModal.react');
const modalService = require('../../../../../../common/react/Modal/ModalManager/services/ModalService');

const ItemsSearch = ({ keyword, placeholder, filter }) => {
  function onExportClick() {
    modalService.openModal(<ExportModal />);
  }

  return (
    <TableSearch
      placeholder={placeholder}
      keyword={keyword}
      filter={filter}
      onExportClick={onExportClick} />
  );
};

module.exports = connect((state) => ({
  placeholder: 'Search',
  keyword: state.keyword,
}), {
  showModal: actions.showModal,
})(ItemsSearch);
