const React = require('react');
const { connect } = require('react-redux');
const actions = require('../actions');
const MenuItem = require('../../../../../../common/react/Menu/ListMenu/MenuItem.react');
const Menu = require('../../../../../../common/react/Menu/Menu.react');
const RoundedButton = require('../../../../../../common/react/Button/RoundedButton.react');
const sidebarService = require('../../../../../../common/react/sidebar/SidebarManager/services/SidebarService');
const modalService = require('../../../../../../common/react/Modal/ModalManager/services/ModalService');
const AddUser = require('./sidebar/AddUser.react');
const ImportMetadataModal = require('./modals/ImportMetadataModal.react');

class NewUserAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };

    this.setMenuAnchorRef = this.setMenuAnchorRef.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.addUser = this.addUser.bind(this);
    this.importMetadata = this.importMetadata.bind(this);
  }

  setMenuAnchorRef(ref) {
    this.button = ref;
  }

  toggleMenu() {
    this.setState((prevState) => ({ open: !prevState.open }));
  }

  addUser() {
    sidebarService.openSidebar(<AddUser />);
    this.toggleMenu();
  }

  importMetadata() {
    modalService.openModal(<ImportMetadataModal />);
    this.toggleMenu();
  }

  render() {
    return (
      <div className="teams__new-user-action">
        <RoundedButton onClick={this.toggleMenu}>
          New User
        </RoundedButton>
        <div ref={this.setMenuAnchorRef} />
        <Menu
          open={this.state.open}
          onClose={this.toggleMenu}
          anchorEl={this.button}>
          <MenuItem onClick={this.addUser}>Add manually</MenuItem>
          <MenuItem onClick={this.importMetadata}>Import metadata</MenuItem>
        </Menu>
      </div>
    );
  }
}

module.exports = connect(null, {
  showSidebar: actions.showSidebar,
})(NewUserAction);
