const request = require('./Request');
const fileRequest = require('./FileRequest');

const Team = {
  getAll() {
    return request({
      method: 'get',
      url: '/account/employeesinfo',
    });
  },
  getInvitations() {
    return request({
      method: 'get',
      url: '/account/invitations',
    });
  },
  createInvitations(data) {
    return request({
      method: 'post',
      url: '/account/inviteusers',
      data,
    });
  },
  exportMetadata() {
    return request({
      method: 'get',
      url: '/export/team/knowledge',
    });
  },
  savePriceListLevel(employeeId, { levelId, pricelistId }) {
    return request({
      method: 'post',
      url: `/account/set-employee-price-list-level?employeeId=${employeeId}&levelId=${levelId}&pricelistId=${pricelistId}`,
    });
  },
  saveRole(employeeId, { roleId }) {
    return request({
      method: 'post',
      url: '/account/setemployeerole',
      data: {
        employeeId,
        roleId,
      },
    });
  },
  saveJobTitle(employeeId, { jobTitleId }) {
    return request({
      method: 'post',
      url: '/employee-job-title',
      data: {
        employee_id: employeeId,
        job_title_id: jobTitleId,
      },
    });
  },
  saveTag(employeeId, { tagId, employeeTagId = '' }) {
    return request({
      method: 'put',
      url: `/employeetag${employeeTagId ? `/${employeeTagId}` : ''}`, // if the employee already has a relation with a tag-category, employeeTagId has to be added to the api url. otherwise a new relation is created (in this case employeeTagId should not be added).
      data: {
        id_employee: employeeId,
        id_tag_emp: tagId,
      },
    });
  },
  getTagsAutocomplete({ hints, category } = {}) {
    const params = hints ? `?hints=${hints}&category=${category}` : `?category=${category}`;
    return request({
      method: 'get',
      url: `/tagempboard/autocomplete${params}`,
    });
  },
  deleteInvitation({ code }) {
    return request({
      method: 'delete',
      url: `/account/retireinvitation?code=${code}`,
    });
  },
  showOnPlanning(employeeId) {
    return request({
      method: 'post',
      url: `/account/employeevisibility/${employeeId}`,
      data: {
        visible: true,
      },
    });
  },
  hideFromPlanning(employeeId) {
    return request({
      method: 'post',
      url: `/account/employeevisibility/${employeeId}`,
      data: {
        visible: false,
      },
    });
  },
  setTimesheetNeeded(employeeId, { needed }) {
    return request({
      method: 'post',
      url: `/account/employee-timesheet-needed/${employeeId}?needed=${needed}`,
    });
  },
  archiveEmployee(employeeId) {
    return request({
      method: 'post',
      url: `/account/employeearchive/${employeeId}`,
    });
  },
  unarchiveEmployee(employeeId) {
    return request({
      method: 'post',
      url: `/account/employeeunarchive/${employeeId}`,
    });
  },
  updateAllocationManager(employeeId, { allocationManagerId }) {
    return request({
      method: 'post',
      url: `/account/employeeallocationmanager/${employeeId}`,
      data: {
        allocation_manager_id: allocationManagerId,
      },
    });
  },
  getChangeLog(employeeId) {
    return request({
      method: 'get',
      url: `/account/employee-changelog/${employeeId}`,
    });
  },
  saveUserCode(employeeId, data) {
    return request({
      method: 'post',
      url: `/account/employee/${employeeId}/external-identifier`,
      data,
    });
  },
  updateUserCode(codeId, data) {
    return request({
      method: 'put',
      url: `/account/external-identifier/${codeId}`,
      data,
    });
  },
  deleteUserCode(codeId) {
    return request({
      method: 'delete',
      url: `/account/external-identifier/${codeId}`,
    });
  },
  importMetadata(file) {
    return fileRequest({
      method: 'post',
      url: '/import/team/knowledge',
      file,
    });
  },
};

module.exports = Team;
