const React = require('react');
const Actions = require('./Actions.react');
const TeamList = require('./TeamList.react');
const ModalManager = require('../../../../../../common/react/Modal/ModalManager/ModalManager.react');
const SidebarManager = require('../../../../../../common/react/sidebar/SidebarManager/SidebarManager.react');

const Team = () => (
  <div className="wethod-section-body team">
    <Actions />
    <TeamList />
    <ModalManager />
    <SidebarManager />
  </div>
);

module.exports = Team;
