const React = require('react');
const { connect } = require('react-redux');
const actions = require('../../actions');
const Modal = require('../../../../../../../common/react/Modal/Modal.react');
const ModalContent = require('../../../../../../../common/react/Modal/ModalContent.react');
const ModalFooter = require('../../../../../../../common/react/Modal/ModalFooter.react');
const ModalBody = require('../../../../../../../common/react/Modal/ModalBody.react');
const ModalFooterAction = require('../../../../../../../common/react/Modal/ModalFooterAction.react');
const ModalBodyBlock = require('../../../../../../../common/react/Modal/ModalBodyBlock.react');
const Typography = require('../../../../../../../common/react/Typography/Typography.react');
const modalService = require('../../../../../../../common/react/Modal/ModalManager/services/ModalService');

class RevokeInvitationModal extends React.Component {
  static onClose() {
    modalService.closeModal();
  }

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      error: null,
    };
    this.onOk = this.onOk.bind(this);
  }

  onOk() {
    this.setState({ isLoading: true });
    this.props.deleteInvitation({ code: this.props.user.code })
      .always(() => {
        this.setState({ isLoading: false });
      })
      .done(() => {
        this.props.getItems();
        this.props.onSuccess?.();
        RevokeInvitationModal.onClose();
      })
      .fail((response) => {
        if (typeof response === 'string') {
          this.setState({ error: response });
        }
      });
  }

  render() {
    return (
      <Modal onClose={RevokeInvitationModal.onClose} title="Revoke Invitation">
        <ModalContent isLoading={this.state.isLoading}>
          <ModalBody>
            <ModalBodyBlock>
              Are you sure you want to revoke the invitation for
              <Typography weight={Typography.WEIGHTS.BOLD}>{ this.props.user.email }</Typography>?
            </ModalBodyBlock>
          </ModalBody>
          <ModalFooter error={this.state.error}>
            <ModalFooterAction onClick={RevokeInvitationModal.onClose}>Cancel</ModalFooterAction>
            <ModalFooterAction onClick={this.onOk}>Revoke</ModalFooterAction>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
}

module.exports = connect(null, {
  getItems: actions.getItems,
  deleteInvitation: actions.deleteInvitation,
})(RevokeInvitationModal);
