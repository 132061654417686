const React = require('react');
const { connect } = require('react-redux');
const actions = require('../../actions');
const Sidebar = require('../../../../../../../common/react/sidebar/Sidebar.react');
const OutlinedSelect = require('../../../../../../../common/react/inputs/Select/OutlinedSelect/OutlinedSelect.react');
const OutlinedTextField = require('../../../../../../../common/react/inputs/TextField/OutlinedTextField/OutlinedTextField.react');
const SelectItem = require('../../../../../../../common/react/inputs/Select/SelectItem.react');
const InputWrapper = require('../../../../../../../common/react/sidebar/SidebarInputWrapper.react');
const Row = require('../../../../../../../common/react/sidebar/SidebarInputRow.react');

class AddUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      employee_email: '',
      employee_role: null,
      employee_level: null,
      isSaving: false,
      error: null,
    };

    this.getLevelItems = this.getLevelItems.bind(this);
    this.getRoleItems = this.getRoleItems.bind(this);
    this.getBody = this.getBody.bind(this);
    this.canSave = this.canSave.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  handleChange(name, e) {
    this.setState({ [name]: e.target.value });
  }

  onSave() {
    this.setState({ isSaving: true });
    this.props.inviteUser({
      email: this.state.employee_email,
      role: this.state.employee_role,
      level: this.state.employee_level,
    })
      .always(() => {
        this.setState({ isSaving: false });
      })
      .done(() => {
        this.props.onCloseClick();
        this.props.getItems();
      })
      .fail((response) => {
        if (typeof response === 'string') {
          this.setState({ error: response });
        }
      });
  }

  getLevelItems() {
    return this.props.levels.map((item) => (
      <SelectItem key={item.id} value={item.text}>
        {item.text}
      </SelectItem>
    ));
  }

  getRoleItems() {
    return this.props.roles.map((item) => (
      <SelectItem key={item.id} value={item.name}>
        {item.name}
      </SelectItem>
    ));
  }

  getBody() {
    return (
      <div className="team-sidebar__body">
        <Row>
          <InputWrapper>
            <OutlinedTextField
              id="employee_email"
              name="employee_email"
              type="email"
              label="Email"
              autocomplete="off"
              required
              value={this.state.employee_email}
              onChange={this.handleChange.bind(this, 'employee_email')} />
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <OutlinedSelect
              label="Level"
              required
              id="employee_level"
              name="employee_level"
              value={this.state.employee_level}
              onChange={this.handleChange.bind(this, 'employee_level')}>
              {this.getLevelItems()}
            </OutlinedSelect>
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <OutlinedSelect
              label="Role"
              required
              id="employee_role"
              name="employee_role"
              value={this.state.employee_role}
              onChange={this.handleChange.bind(this, 'employee_role')}>
              {this.getRoleItems()}
            </OutlinedSelect>
          </InputWrapper>
        </Row>
      </div>
    );
  }

  canSave() {
    return !!(
      this.state.employee_email
      && this.state.employee_role
      && this.state.employee_level
    );
  }

  render() {
    return (
      <Sidebar
        title="Add manually"
        body={this.getBody()}
        error={this.state.error}
        onClose={this.props.onCloseClick}
        onSave={this.onSave}
        hasUnsavedChanges
        isSaving={this.state.isSaving}
        canSave={this.canSave()}
        onCancel={this.props.onCloseClick} />
    );
  }
}

module.exports = connect((state) => ({
  levels: state.levels,
  roles: state.roles,
}), {
  inviteUser: actions.inviteUser,
  getItems: actions.getItems,
})(AddUser);
