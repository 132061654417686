/* eslint-disable react/no-did-update-set-state,react/sort-comp,class-methods-use-this,
 consistent-return, react/no-array-index-key */
const React = require('react');
const moment = require('moment');
const TableRow = require('../../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../../common/react/Table2/TableCell.react');
const Escaper = require('../../../../../../../common/react/formatters/Escaper.react');
const OptionsCell = require('../containers/ProjectOptionsCell');
const NoteCell = require('../containers/ProjectNoteCell');
const HoursCell = require('./ProjectHoursCell.react');
const TotalCell = require('./ProjectTotalCell.react');
const InfoCell = require('./ProjectInfoCell.react');
const TimesheetAutomaticTagCell = require('./TimesheetAutomaticTag.react');
const { isInWhitelist } = require('../../services/projectService');
const FeatureService = require('../../services/FeatureService');

module.exports = class DailyProject extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      unsavedChanges: false,
      project: this.props.project,
      showTooltip: false,
    };

    this.daysList = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.project.hours !== prevState.project.hours) {
      this.setState({ project: this.props.project });
    }
  }

  isWaiting() {
    return this.props.isWaitingProject(this.props.project.project.id);
  }

  handleHoursChange(name, value) {
    const dayHours = this.props.project.detail[name] || 0;
    if (value !== dayHours) {
      this.setState({
        unsavedChanges: true,
      });
    } else {
      this.setState({
        unsavedChanges: false,
      });
    }
  }

  handleMouseEnter() {
    const isTimesheetEditable = this.props.project.can_edit;
    const whitelistEditable = isInWhitelist(this.props.project.project);
    const archiveEditable = !this.props.project.project.archived;
    if (!isTimesheetEditable || !whitelistEditable || !archiveEditable) {
      this.setState({ showTooltip: true });
    }
  }

  handleMouseLeave() {
    this.setState({ showTooltip: false });
  }

  getFeedback() {
    if (this.isWaiting()) {
      return 'Saving';
    }
    if (this.state.unsavedChanges) {
      return 'Unsaved Changes';
    }
  }

  handleHoursUpdate(value, name) {
    const dayHours = this.props.project.detail[name] || 0;
    if (value !== dayHours) {
      let updatedProject = {
        ...this.props.project,
        detail: {
          ...this.props.project.detail,
          [name]: value,
        },
      };
      const totalHours = Math.round(
        this.daysList.reduce((sum, day) => updatedProject.detail[day] + sum, 0)
          * 100,
      ) / 100;
      updatedProject = {
        ...updatedProject,
        hours: totalHours,
      };
      if (totalHours) {
        this.props.updateHours(updatedProject);
      } else {
        this.props.deleteHours(updatedProject);
      }
      this.setState({
        unsavedChanges: false,
        project: updatedProject,
      });
    }
  }

  getDaysCells() {
    let { daysList } = this;
    if (!this.props.internationalCalendar) {
      daysList = daysList.slice(0, 5);
    }

    return daysList.map((day, index) => {
      const date = moment(this.props.date).day(day).format('YYYY-MM-DD');
      if (FeatureService.isEmployeeCapacityEnabled()) {
        return (
          <HoursCell key={index}
            isHoliday={this.props.isHoliday(date)}
            className="timesheet-weekly__cell-day hidden-mobile"
            name={day}
            value={this.props.project.detail[day]}
            totalHours={this.props.project.hours}
            editable={this.canEditHours()}
            isInWhitelist={isInWhitelist(this.props.project.project)}
            isWaiting={this.isWaiting()}
            onChange={this.handleHoursChange.bind(this)}
            updateHours={this.handleHoursUpdate.bind(this)} />
        );
      }
      return (
        <HoursCell key={index}
          className="timesheet-weekly__cell-day hidden-mobile"
          name={day}
          value={this.props.project.detail[day]}
          totalHours={this.props.project.hours}
          editable={this.canEditHours()}
          isInWhitelist={isInWhitelist(this.props.project.project)}
          isWaiting={this.isWaiting()}
          onChange={this.handleHoursChange.bind(this)}
          updateHours={this.handleHoursUpdate.bind(this)} />
      );
    });
  }

  getProjectBody() {
    return [
      <OptionsCell key="options"
        project={this.props.project} />,
      <InfoCell key="project"
        project={this.props.project}
        showTooltip={this.state.showTooltip} />,
      <TimesheetAutomaticTagCell key="automatic_timesheet"
        automatic={this.props.project.project.project_type.is_timesheet_automatic} />,
      <TableCell key="job_order"
        className="timesheet-weekly__cell-job-order hidden-mobile">
        <Escaper>{this.props.project.project.job_order}</Escaper>
      </TableCell>,
      <NoteCell key="notes"
        project={this.props.project}
        isWaiting={this.isWaiting()} />,
    ]
      .concat(this.getDaysCells())
      .concat(<TotalCell key="total"
        className="timesheet-weekly__cell-total hidden-mobile"
        value={this.props.project.hours}
        totalHours={this.props.project.hours}
        project={this.props.project.project}
        feedback={this.getFeedback()}
        canEdit={this.props.project.can_edit}
        editable={false}
        showTooltip={this.state.showTooltip}
        isInWhitelist={isInWhitelist(this.props.project.project)}
        reasonCannotEdit={this.props
          .reasonWhyCannotEditHour(this.props.project.reason_cannot_edit)} />)
      .concat(<TotalCell key="selected"
        isHoliday={FeatureService.isEmployeeCapacityEnabled()
                           && this.props.isHoliday(this.props.date)}
        className="timesheet-weekly__cell-total timesheet-weekly__cell-total--mobile"
        name={this.props.selectedDayLabel}
        value={this.props.project.detail[this.props.selectedDayLabel]}
        totalHours={this.props.project.hours}
        editable={this.canEditHours()}
        canEdit={this.props.project.can_edit}
        project={this.props.project.project}
        feedback={this.getFeedback()}
        isWaiting={this.isWaiting()}
        onChange={this.handleHoursChange.bind(this)}
        updateHours={this.handleHoursUpdate.bind(this)}
        showTooltip={this.state.showTooltip}
        isInWhitelist={isInWhitelist(this.props.project.project)}
        reasonCannotEdit={this.props
          .reasonWhyCannotEditHour(this.props.project.reason_cannot_edit)} />);
  }

  /**
   * Check whether the logged user can edit the hours of the timesheet.
   * The permissions consider the automatic thimesheet, the whitelist and the archived status.
   * @returns {*|boolean}
   */
  canEditHours() {
    const isTimesheetEditable = this.props.project.can_edit;
    const whitelistEditable = isInWhitelist(this.props.project.project);
    const archiveEditable = !this.props.project.project.archived;
    return isTimesheetEditable && whitelistEditable && archiveEditable;
  }

  render() {
    return (
      <TableRow className="timesheet-weekly__row timesheet-weekly__row--body"
        onMouseEnter={this.handleMouseEnter.bind(this)}
        onMouseLeave={this.handleMouseLeave.bind(this)}>
        {this.getProjectBody()}
        {this.state.showTooltip
          && (
            <TableCell className="timesheet__tooltip tooltipReact">
              <span style={{
                position: 'relative',
                bottom: '50%',
              }}>
                {this.props.reasonWhyCannotEditHour(this.props.project.reason_cannot_edit).message}
              </span>
            </TableCell>
          )}
      </TableRow>
    );
  }
};
