const React = require('react');
const numeral = require('numeral');
const Table = require('../../../../../../../common/react/Table2/Table2.react');
const TableHead = require('./WeeklyTableHead.react');
const TableBody = require('../../../../../../../common/react/Table2/TableBody.react');
const Project = require('./WeeklyProject.react');
const Loader = require('../../../../../../../common/react/Loader/Loader.react');
const FeatureService = require('../../services/FeatureService');
const PermissionManager = require('./PermissionManager.react');

module.exports = class WeeklyTimesheet extends React.Component {
  getEmptyContent() {
    if (this.props.isWaitingForProjects) return <Loader />;
    return (
      <span>
        There's nothing here! What about
        <a href="/#pipeline/projects"> starting a new project</a>?
      </span>
    );
  }

  getProjects() {
    if (!this.props.isWaitingForProjects) {
      return this.props.projects.map((project) => (
        <PermissionManager>
          <Project
            key={project.project.id}
            project={project}
            isWaitingProject={this.props.isWaitingProject}
            updateHours={this.props.updateHours}
            deleteHours={this.props.deleteHours} />
        </PermissionManager>
      ));
    }
    return [];
  }

  getTotalHours() {
    const totalHours = Math.round(
      this.props.allProjects.reduce((sum, project) => sum + project.hours, 0) * 100,
    ) / 100;
    return numeral(totalHours).format('0,0.[00]');
  }

  getCapacity() {
    if (FeatureService.isEmployeeCapacityEnabled()) {
      return numeral(this.props.getWeekCapacity()).format('0,0.[00]');
    }
    return 40;
  }

  render() {
    return (
      <Table columns={5} maxHeight={this.props.tableHeight} search={this.props.search}>
        <TableHead hours={this.getTotalHours()} capacity={this.getCapacity()} />
        <TableBody
          editable
          hasMore={this.props.hasMore}
          loadMore={this.props.loadMore}
          empty={this.getEmptyContent()}>
          {this.getProjects()}
        </TableBody>
      </Table>
    );
  }
};
