const { connect } = require('react-redux');
const Component = require('../components/Planning.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  selectedProject: state.selected_project_id,
  userInfo: state.people.filter((person) => person.id === state.user_id)[0],
  people_filters: state.people_filters,
  days: state.days,
  idTour: state.idTour,
  loading: state.waiting_for.filter((request) => request === 'project-availability' || request === 'plan').length > 0,
  firstLoading: state.waiting_for.filter((request) => request === 'people'
    || request === 'available-people-filters'
    || request === 'international-calendar'
    || request === 'fte').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  selectProject: (projectId) => dispatch(actions.getProjectAvailability(projectId)),
  onSearchKeywordChange: (keyword, mode) => dispatch(actions.searchKeywordChange(keyword, mode)),
  getPlan: (from, to) => dispatch(actions.getPlan(from, to)),
  getPeople: () => dispatch(actions.getPeople()),
  getAvailableProjects: () => dispatch(actions.getAvailableProjects()),
  getAvailablePeopleFilters: () => dispatch(actions.getAvailablePeopleFilters()),
  isInternationalCalendar: () => dispatch(actions.isInternationalCalendar()),
  getFte: () => dispatch(actions.getFte()),
  syncPlan: (changes) => dispatch(actions.syncPlan(changes)),
  selectPeopleFilter: (type, target, secondaryTarget) => dispatch(actions.selectPeopleFilter(type,
    target, secondaryTarget)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
