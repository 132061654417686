const request = require('./Request');

const Role = {
  list() {
    return request({
      method: 'get',
      url: '/role/',
    });
  },
};

module.exports = Role;
