const FeatureService = require('../../../../../../services/FeatureService');

// TODO delete on 'JAKALA_FEATURES_NOVEMBER_BLOCK' contract
module.exports = {
  isEmployeeCapacityEnabled() {
    return Wethod.featureService.isEnabled(FeatureService.JAKALA_FEATURES_NOVEMBER_BLOCK);
  },
  // TODO [FTE_CONTRACT] Remove this method when FTE is released for everyone
  isFteEnabled() {
    return Wethod.featureService.isEnabled(FeatureService.FTE);
  },
};
